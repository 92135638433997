import { IColorSchemaConfig } from "../../infrastructure/color-schema-config";
import { defaultTheme, toStyledThemeColor } from "./helper";
import { ApplicationTheme } from "@agp/shared.common/entities/application-config";
import { IPreviewConfig } from "@agp/user-app/src/infrastructure/preview-config";
import { MasterData } from "../../entity";
import { ITheme } from "./type";

export class ThemeStore {
  private readonly masterData: MasterData;
  private readonly colorSchemaConfig: IColorSchemaConfig;
  private readonly previewConfig: IPreviewConfig;
  public readonly theme: ITheme;

  public constructor(
    masterData: MasterData,
    colorSchemaConfig: IColorSchemaConfig,
    previewConfig: IPreviewConfig
  ) {
    this.masterData = masterData;
    this.colorSchemaConfig = colorSchemaConfig;
    this.previewConfig = previewConfig;
    this.theme = this.createTheme();
  }

  private createTheme = () => {
    const applicationConfig = this.masterData.applicationConfig;

    const fixedTheme = Object.assign(
      {},
      applicationConfig.fixedTheme,
      this.previewConfig.previewTheme
    ) as ApplicationTheme;
    const darkTheme = Object.assign(
      {},
      applicationConfig.lightTheme,
      this.previewConfig.previewTheme
    ) as ApplicationTheme;
    const lightTheme = Object.assign(
      {},
      applicationConfig.darkTheme,
      this.previewConfig.previewTheme
    ) as ApplicationTheme;

    const theme = Object.assign({}, defaultTheme, {
      color: toStyledThemeColor(
        applicationConfig.themeSwitchingType === "Fixed"
          ? fixedTheme
          : this.colorSchemaConfig.colorSchema === "dark"
          ? darkTheme
          : lightTheme
      ),
    });
    return theme;
  };
}

import {
  IReactiveProperty,
  IReadOnlyReactiveProperty,
  ReactivePropertyInstance,
} from "@agp/shared.common/use-reactive-property";
import { IPersistentDataModule } from "../persistent-data.module";
import { Area, Guide, Tour } from "../../entity";
import { LocalizedEntityRepository } from "../localized-entity.repository";

export class ApplicationStateRepository {
  private readonly persistentDataModule: IPersistentDataModule;
  private readonly _listenedGuideIds: IReactiveProperty<string[]>;

  private readonly _currentAreaId = new ReactivePropertyInstance<string | null>(
    null
  );
  private readonly _currentTourId = new ReactivePropertyInstance<string | null>(
    null
  );
  private readonly _currentGuideId = new ReactivePropertyInstance<
    string | null
  >(null);
  private readonly _currentLabel = new ReactivePropertyInstance<string | null>(
    null
  );

  public currentAreaId = this._currentAreaId.toReactiveProperty();
  public currentTourId = this._currentTourId.toReactiveProperty();
  public currentGuideId = this._currentGuideId.toReactiveProperty();
  public currentLabel = this._currentLabel.toReactiveProperty();
  public listenedGuideIds;

  public readonly currentArea: IReadOnlyReactiveProperty<Area | null>;
  public readonly currentTour: IReadOnlyReactiveProperty<Tour | null>;
  public readonly currentGuide: IReadOnlyReactiveProperty<Guide | null>;

  public constructor(
    localizedEntityRepository: LocalizedEntityRepository,
    persistentDataModule: IPersistentDataModule
  ) {
    this.persistentDataModule = persistentDataModule;
    this._listenedGuideIds = new ReactivePropertyInstance<string[]>(
      this.persistentDataModule.getListenedGuideIds()
    );
    this.listenedGuideIds = this._listenedGuideIds.toReadOnlyReactiveProperty();

    this.currentArea = ReactivePropertyInstance.combineLatest(
      localizedEntityRepository.entities.getInstance(),
      this._currentAreaId,
      (entities, areaId) => entities.areas.find((x) => x.id === areaId) || null
    );
    this.currentTour = ReactivePropertyInstance.combineLatest(
      localizedEntityRepository.entities.getInstance(),
      this._currentTourId,
      (entities, tourId) => entities.tours.find((x) => x.id === tourId) || null
    );
    this.currentGuide = ReactivePropertyInstance.combineLatest(
      localizedEntityRepository.entities.getInstance(),
      this._currentGuideId,
      (entities, guideId) =>
        entities.guides.find((x) => x.id === guideId) || null
    );
  }

  public addListenedGuideId = (id: string) => {
    if (this.listenedGuideIds.value.includes(id)) return;
    this._listenedGuideIds.setValue((x) => [...x, id]);
    this.persistentDataModule.updateListenedGuideIds(
      this._listenedGuideIds.value
    );
  };
}

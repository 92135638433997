import {
  ApiClientFilter,
  ServiceContext,
} from "../../../infrastructure/api-client";

export class JsonContentTypeFilter extends ApiClientFilter {
  async invoke(
    context: ServiceContext,
    next: (serviceContext: ServiceContext) => Promise<void>
  ): Promise<void> {
    context.request.headers.set("Content-Type", "application/json");
    await next(context);
  }
}

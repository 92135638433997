import { MasterData } from "../../entity";
import { UserAppApiModule } from "../api.module";

export type IMasterDataModule = {
  masterData: MasterData;
};

export class MasterDataModule {
  private apiModule: UserAppApiModule;
  public masterData: MasterData;

  public constructor(apiModule: UserAppApiModule) {
    this.apiModule = apiModule;
  }

  public fetch = async (applicationId: string, isPreview?: boolean) => {
    const response = await this.apiModule.services.masterDataService.get(
      applicationId,
      isPreview
    );
    this.masterData = response.master;
    return this.masterData;
  };
}

import styled from "styled-components";
import { useCallback, useMemo, useRef, useState } from "react";

export interface GradatedImageProps {
  rangeRate: number; // グラデーションさせる範囲 0 ~ 1
  src: string;
  color?: string;
  height?: string;
}

const GradatedImage = (props: GradatedImageProps) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const [imageHeight, setImageHeight] = useState<number>(0);

  const gradationTop = useMemo(
    () => `${(1 - props.rangeRate) * 100}%`,
    [props.rangeRate]
  );

  const onLoadImage = useCallback(
    (e: any) => setImageHeight(e.target.getBoundingClientRect().height),
    []
  );

  return (
    <>
      <Wrapper height={props.height ?? `${imageHeight}px`}>
        <ImageWrapper
          gradationTop={gradationTop}
          color={props.color || "black"}
        >
          <Image src={props.src} ref={imageRef} onLoad={onLoadImage} />
        </ImageWrapper>
      </Wrapper>
    </>
  );
};

export default GradatedImage;

const Wrapper = styled.div<{ height: string }>`
  position: relative;
  width: 100%;
  height: ${(props) => props.height};
  overflow: hidden;
`;

const ImageWrapper = styled.div<{
  gradationTop: string;
  color: string;
}>`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  &:before {
    content: "";
    position: absolute;
    top: ${(props) => props.gradationTop};
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    background: linear-gradient(
      rgba(255, 255, 255, 0),
      ${(props) => props.color}
    );
  }
`;

const Image = styled.img`
  width: 100%;
  display: inline-block;
  line-height: 0;
`;

import { IAudioPlayerClient, IAudioSpeechClient, ITrack } from "./audio-client";
import { ReactivePropertyInstance } from "@agp/shared.common/use-reactive-property";
import { Guide } from "../../entity";
import {
  guideToPlayerTrack,
  guideToSpeechTrack,
} from "../../extension/entity-extension/guide.extension";
import { LocalizedEntityRepository } from "../localized-entity.repository";
import { Language } from "@agp/shared.common/entities/language";
import { PlayerMode } from "./player-mode";
import { ApplicationStateRepository } from "../application-state.repository";

export class GuidePlayerModule {
  private readonly audioPlayerClient: IAudioPlayerClient;
  private readonly audioSynthesisClient: IAudioSpeechClient;
  private readonly localizedEntityRepository: LocalizedEntityRepository;
  private readonly applicationStateRepository: ApplicationStateRepository;
  private readonly languages: Language[];

  private readonly _currentTrack = new ReactivePropertyInstance<ITrack | null>(
    null
  );
  private readonly _playerMode = new ReactivePropertyInstance<PlayerMode>(
    "AudioPlayer"
  );
  private readonly _canNext = new ReactivePropertyInstance<boolean>(false);
  private readonly _canPrev = new ReactivePropertyInstance<boolean>(false);
  private readonly _currentSeek = new ReactivePropertyInstance<number>(0);
  private readonly _isPlaying = new ReactivePropertyInstance<boolean>(false);
  private readonly _currentTourId = new ReactivePropertyInstance<string | null>(
    null
  );
  private readonly _client = this._playerMode.select((x) =>
    x === "AudioSynthesis" ? this.audioSynthesisClient : this.audioPlayerClient
  );

  public currentTrack = this._currentTrack.toReadOnlyReactiveProperty();
  public isPlaying = this._isPlaying.toReadOnlyReactiveProperty();
  public currentSeek = this._currentSeek.toReadOnlyReactiveProperty();
  public canNext = this._canNext.toReadOnlyReactiveProperty();
  public canPrev = this._canPrev.toReadOnlyReactiveProperty();
  public playerMode = this._playerMode.toReadOnlyReactiveProperty();

  public constructor(
    audioPlayerClient: IAudioPlayerClient,
    audioSynthesisClient: IAudioSpeechClient,
    localizedEntityRepository: LocalizedEntityRepository,
    applicationStateRepository: ApplicationStateRepository,
    languages: Language[]
  ) {
    this.audioPlayerClient = audioPlayerClient;
    this.audioSynthesisClient = audioSynthesisClient;
    this.localizedEntityRepository = localizedEntityRepository;
    this.applicationStateRepository = applicationStateRepository;
    this.languages = languages;

    // 言語が変更され、ローカライズされたエンティティに変更が加わった時、ライブラリをリセット
    localizedEntityRepository.entities.getInstance().subscribe((_) => {
      this.audioPlayerClient.reset();
      this.audioSynthesisClient.reset();
      this._currentTourId.setValue(null);
    });

    this.audioPlayerClient.currentTrack
      .getInstance()
      .subscribe((x) => this._currentTrack.setValue(x));
    this.audioPlayerClient.isPlaying
      .getInstance()
      .subscribe((x) => this._isPlaying.setValue(x));
    this.audioPlayerClient.currentSeek
      .getInstance()
      .subscribe((x) => this._currentSeek.setValue(x));
    this.audioSynthesisClient.currentTrack
      .getInstance()
      .subscribe((x) => this._currentTrack.setValue(x));
    this.audioSynthesisClient.isPlaying
      .getInstance()
      .subscribe((x) => this._isPlaying.setValue(x));
    this.audioSynthesisClient.currentSeek
      .getInstance()
      .subscribe((x) => this._currentSeek.setValue(x));
  }

  public play = () => {
    if (!this._currentTrack.value) return;
    this._client.value.play();
    this.applicationStateRepository.addListenedGuideId(
      this._currentTrack.value.id
    );
  };

  public playOrPause = () => {
    this._isPlaying.value ? this._client.value.pause() : this.play();
  };

  public pause = () => this._client.value.pause();

  public seek = (to: number) => this._client.value.seek(to);

  public next = () => this.go(1);

  public prev = () => this.go(-1);

  public stop = () => this._client.value.reset();

  public select = (guideId: string) => {
    const entities = this.localizedEntityRepository.entities.value;
    const guide = entities.guides.find((x) => x.id === guideId);
    if (!guide) throw new Error(`Could not find a guide: ${guideId}`);

    // Tour が変更されている場合、プレイヤーの更新とライブラリの更新を行う
    if (guide.tourId !== this._currentTourId.value)
      this.updatePlayerFromGuide(guide);

    this.selectImpl(guideId);
  };

  private selectImpl = (trackId: string) => {
    this._client.value.selectTrack(trackId);
    this._canNext.setValue(this.checkCanGo(1));
    this._canPrev.setValue(this.checkCanGo(-1));
  };

  /**
   * trackNumberDiff 分離れたインデックスのトラックが存在するか確認する
   * @param trackNumberDiff
   */
  private checkCanGo = (trackNumberDiff: number) => {
    const library = this.getValidLibrary();
    const nextIndex = this.getIndex(trackNumberDiff);
    return library.length >= 0 && library.length < nextIndex;
  };

  /**
   * trackNumberDiff 分インデックスが離れたトラックを選択する
   * @private
   */
  private go = (trackNumberDiff: number) => {
    if (!this.checkCanGo(trackNumberDiff))
      throw new Error("Couldn't find next guide.");
    const library = this.getValidLibrary();
    const index = this.getIndex(trackNumberDiff);
    this.selectImpl(library[index].id);
  };

  /**
   * 選択したいガイドに見合ったプレイヤータイプへの変更と、ライブラリの更新を行う
   * @param guide
   */
  private updatePlayerFromGuide = (guide: Guide) => {
    const entities = this.localizedEntityRepository.entities.value;
    const applicationName = entities.application.name;
    const tour = entities.tours.find((x) => x.id === guide.tourId);
    if (!tour) throw new Error(`Could not find a tour: ${guide.tourId}`);
    const guides = entities.guides.filter((x) => x.tourId === tour.id);

    this.audioSynthesisClient.reset();
    this.audioPlayerClient.reset();
    if (tour.useAutoSpeech) {
      this._playerMode.setValue("AudioSynthesis");
      this.audioSynthesisClient.setLibrary(
        guides.map((x) =>
          guideToSpeechTrack(x, applicationName, this.languages)
        )
      );
    } else {
      this._playerMode.setValue("AudioPlayer");
      this.audioPlayerClient.setLibrary(
        guides.map((x) => guideToPlayerTrack(x, applicationName))
      );
    }
  };

  /**
   * ライブラリを取得する
   * ライブラリがない場合、エラーをスロー
   * @private
   */
  private getValidLibrary = () => {
    if (!this._client.value.currentLibrary)
      throw new Error("Can't call getValidLibrary with no library set");
    return this._client.value.currentLibrary;
  };

  /**
   * CurrentTrack を取得する
   * CurrentTrack がない場合、エラーをスロー
   * @private
   */
  private getValidCurrentTrack = () => {
    if (!this._client.value.currentTrack.value)
      throw new Error("Can't call getValidCurrentTrack with no track set");
    return this._client.value.currentTrack.value;
  };

  /**
   * currentTrack から trackNumberDiff分 index が離れた track のインデックスを取得する
   * @param trackNumberDiff
   */
  private getIndex = (trackNumberDiff: number) => {
    const library = this.getValidLibrary();
    const currentTrack = this.getValidCurrentTrack();
    return library.findIndex((x) => x.id === currentTrack.id) + trackNumberDiff;
  };
}

import ServiceLocator from "../../../../domain/service-locator";
import { useNavigationModule } from "@agp/shared.user-app/application/context/navigation";
import { useCallback, useMemo } from "react";

export const TopAcoustiguide = () => {
  const localizedEntityRepository = ServiceLocator.LocalizedEntityRepository;
  const { applicationConfig } = ServiceLocator.MasterDataModule.masterData;
  const navigation = useNavigationModule();

  /**
   * useMemo
   */
  const entities = localizedEntityRepository.entities.watch();
  const defaultArea = useMemo(
    () => entities.areas.find((x) => x.isDefault),
    [entities]
  );
  const handleClickStartButton = useCallback(() => {
    if (!applicationConfig) return;
    navigation.push(
      "note",
      {},
      {
        pageOutType: "fade",
        pageInType: "fade",
      }
    );
  }, [applicationConfig, defaultArea]);

  return (
    <div className="bg-[#210917] pt-5 h-[calc(var(--vh,1vh)*100)] text-white pb-8 flex flex-col gap-5 justify-around items-center">
      <img src="/minpaku-demo/logos/minpaku.svg" className="w-[85%] mx-auto" />
      <div
        className="text-[3rem] font-bold flex items-center gap-2"
        onClick={handleClickStartButton}
      >
        スタート
        <img
          src="/minpaku-demo/icons/next.svg"
          className="inline-block w-10 mt-1"
        />
      </div>
      <div className="flex flex-col gap-5 items-center justify-start w-full">
        <div className="bg-[#5b5a5c] rounded-full h-12 w-[75%]" />
        <div className="bg-[#5b5a5c] rounded-full h-12 w-[75%]" />
      </div>
      <img
        src={"/minpaku-demo/minpaku_logo_b.png"}
        className="mx-auto w-[85%]"
      />
    </div>
  );
};

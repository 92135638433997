const getQueries = (): { [key: string]: string } => {
  if (typeof window == "undefined") return {};
  const queryString = window.location.search;
  if (!queryString) return {};
  const queries = queryString.slice(1).split("&");
  return queries.reduce((acc, cur) => {
    const keyValue = cur.split("=");
    acc[keyValue[0]] = keyValue[1];
    return acc;
  }, {} as { [key: string]: string });
};

export const getQuery = (key: string) => {
  if (typeof window == "undefined") return "";
  const queries = getQueries();
  return queries[key];
};

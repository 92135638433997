import { useEffect } from "react";
import { useNavigationModule } from "@agp/shared.user-app/application/context/navigation";
import AnimatedPageTransition from "../../../domain/module/navigation/animated-page-transition";
import ServiceLocator from "../../../domain/service-locator";

const RootPage = () => {
  const navigate = useNavigationModule();
  const uiTemplate = ServiceLocator.UiTemplateModule.uiTemplate;

  useEffect(() => {
    navigate.push(uiTemplate === "Minpaku" ? "splash" : "note", {});
  }, [navigate.push]);

  return <AnimatedPageTransition />;
};

export default RootPage;

export {};

declare global {
  interface Number {
    toPadString(length: number): string;
    toCurrencyJp(): string;
  }
}

Number.prototype.toPadString = function (length: number) {
  const num = (this as number).valueOf();
  const padMax = parseInt("9".repeat(length - 1));
  if (num <= padMax) return (Array(length).join("0") + num).slice(-length);
  return num.toString();
};

Number.prototype.toCurrencyJp = function () {
  const num = (this as number).valueOf();
  return num.toLocaleString("ja-JP", { style: "currency", currency: "JPY" });
};

import { Icon, Menu } from "semantic-ui-react";
import { useCallback, useMemo } from "react";
import { Language } from "@agp/shared.common/entities/language";
import ServiceLocator from "../../../domain/service-locator";

export interface LanguageSelectorProps {
  onComplete: () => void;
}

const LanguageSelector = (props: LanguageSelectorProps) => {
  const applicationStateRepository = ServiceLocator.ApplicationStateRepository;
  const languageRepository = ServiceLocator.LanguageRepository;
  const languages = languageRepository.languages;
  const { application } =
    ServiceLocator.LocalizedEntityRepository.entities.watch();
  const currentLanguageId = languageRepository.currentLanguageId.watch();

  const handleItemClick = useCallback(
    (languageId: string) => {
      languageRepository.updateCurrentLanguageId(languageId);
      props.onComplete();
    },
    [applicationStateRepository, props]
  );

  const applicationLanguages = useMemo<Language[]>(
    () =>
      !application
        ? []
        : application.languageIds.map(
            (x) => languages!.find((y) => y.id === x)!
          ),
    [application, languages]
  );

  return (
    <div>
      {applicationLanguages && currentLanguageId && (
        <Menu vertical secondary style={{ width: "100%" }}>
          {applicationLanguages.map((x, i) => (
            <Menu.Item
              name="inbox"
              key={`languages-item-${i}`}
              active={currentLanguageId === x.id}
              onClick={() => handleItemClick(x.id)}
              style={{ display: "flex", height: 50, alignItems: "center" }}
            >
              <div style={{ width: 25 }}>
                {currentLanguageId === x.id && <Icon name="check" />}
              </div>
              <div>{x.name}</div>
            </Menu.Item>
          ))}
        </Menu>
      )}
    </div>
  );
};

export default LanguageSelector;

import { Icon, Menu } from "semantic-ui-react";
import { useCallback, useState } from "react";
import Modal from "../modal";
import LanguageSelector from "../language-selector";
import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";

type TTopBarMenu = "about" | "language";

export interface TopBarProps {
  visible: boolean;
  onHide: () => void;
}

const TopBar = (props: TopBarProps) => {
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
  const onClickMenu = useCallback(
    (menu: TTopBarMenu) => {
      if (menu === "language") setIsLanguageModalOpen(true);
      if (menu === "about") {
      }
      props.onHide();
    },
    [props.onHide]
  );
  return (
    <>
      <AnimatePresence>
        {props.visible && (
          <>
            <Dimmer
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.5 }}
              exit={{ opacity: 0 }}
              onClick={props.onHide}
              transition={{ ease: "easeOut", duration: 0.3 }}
            />
            <motion.div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 1001,
              }}
              initial={{ y: -150 }}
              animate={{ y: 0 }}
              exit={{ y: -150 }}
              transition={{ ease: "easeOut", duration: 0.3 }}
            >
              <Menu vertical style={{ width: "100%" }} icon="labeled">
                <Menu.Item onClick={() => onClickMenu("about")}>
                  <Icon name="newspaper outline" />
                  About
                </Menu.Item>
                <Menu.Item onClick={() => onClickMenu("language")}>
                  <Icon name="language" />
                  <div>Languages</div>
                </Menu.Item>
              </Menu>
            </motion.div>
          </>
        )}
      </AnimatePresence>
      <Modal
        title="Select Language"
        isModalOpen={isLanguageModalOpen}
        onClose={() => setIsLanguageModalOpen(false)}
      >
        <LanguageSelector onComplete={() => setIsLanguageModalOpen(false)} />
      </Modal>
    </>
  );
};

export default TopBar;

const Dimmer = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: black;
  z-index: 1000;
`;

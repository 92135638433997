import { motion, Variants } from "framer-motion";
import { ReactNode, useEffect, useState } from "react";

const itemVariants: Variants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 300, damping: 24 },
  },
  closed: { opacity: 0, y: 20, transition: { duration: 0.2 } },
};

export interface AnimatedListProps {
  items: ReactNode[];
  disableAnimation?: boolean;
}

const AnimatedList = (props: AnimatedListProps) => {
  const [open, setOpen] = useState<boolean>(props.disableAnimation || false);

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <motion.nav initial={false} animate={open ? "open" : "closed"}>
      <motion.ul
        variants={{
          open: {
            clipPath: "inset(0% 0% 0% 0% round 10px)",
            transition: {
              type: "spring",
              bounce: 0,
              duration: 0.1,
              delayChildren: 0.1,
              staggerChildren: 0.05,
            },
          },
          closed: {
            clipPath: "inset(10% 50% 90% 50% round 10px)",
            transition: {
              type: "spring",
              bounce: 0,
              duration: 0.3,
            },
          },
        }}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2.698vh",
        }}
      >
        {props.items.map((x, i) => (
          <motion.li key={`tour-list-item-${i}`} variants={itemVariants}>
            {x}
          </motion.li>
        ))}
      </motion.ul>
    </motion.nav>
  );
};

export default AnimatedList;

import { motion, useAnimationControls } from "framer-motion";
import { useEffect, useMemo } from "react";
import styled from "styled-components";
import ServiceLocator from "../../../../domain/service-locator";

export interface PlayingAnimationProps {
  active: boolean;
  color?: string;
}

const PlayingAnimation = (props: PlayingAnimationProps) => {
  const { theme } = ServiceLocator.ThemeStore;
  const animationControls = [
    useAnimationControls(),
    useAnimationControls(),
    useAnimationControls(),
    useAnimationControls(),
  ];

  const random = useMemo(
    () => [...Array(4)].map(() => Math.random() * 2 + 0.5),
    []
  );

  useEffect(() => {
    if (props.active)
      animationControls.forEach((x, i) =>
        x.start(
          {
            scaleY: [1, 5, 1],
          },
          {
            duration: random[i],
            ease: "linear",
            times: [0, 0.5, 1],
            repeat: Infinity,
          }
        )
      );
    else
      animationControls.forEach((x) => {
        x.stop();
        x.start({ scaleY: 1 });
      });
  }, [random, props.active]);

  return (
    <>
      <Wrapper>
        {random.map((_, i) => (
          <Bar
            key={`playing-animation-bar-${i}`}
            animate={animationControls[i]}
            color={props.color || theme.color.primary}
          />
        ))}
      </Wrapper>
    </>
  );
};

export default PlayingAnimation;

const Wrapper = styled.div`
  display: flex;
  gap: 1px;
  width: 18px;
  height: 20px;
  justify-content: center;
  align-items: center;
`;

const Bar = styled(motion.div)<{ color: string }>`
  width: 3px;
  height: 4px;
  background: ${(props) => props.color};
`;

import { ReactNode, useEffect } from "react";
import ServiceLocator from "./domain/service-locator";

export interface DocumentProps {
  children: ReactNode;
}

const Document = (props: DocumentProps) => {
  const { application } =
    ServiceLocator.LocalizedEntityRepository.entities.watch();

  useEffect(() => {
    if (application) document.title = application.name;
  }, [application]);

  return <>{props.children}</>;
};

export default Document;

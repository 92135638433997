import { useMemo } from "react";
import {
  NavigationOption,
  PageType,
} from "@agp/shared.user-app/application/context/navigation/type";
import { createUrl } from "./helper";
import {
  INavigationModule,
  INavigationProps,
} from "@agp/shared.user-app/application/context/navigation";
import { INavigationCoreModule } from "../../context/navigation-core";
import { IApplicationConfig } from "@agp/shared.user-app/infrastructure/application-config";

export const useNavigationModule = (
  applicationConfig: IApplicationConfig,
  navigationCoreModule: INavigationCoreModule
): INavigationModule => {
  const push = useMemo(
    () =>
      function <T extends INavigationProps>(
        pageType: PageType,
        navigationProps: T,
        option?: NavigationOption
      ) {
        const url = createUrl(
          pageType,
          applicationConfig.applicationId,
          navigationProps
        );
        navigationCoreModule.push(url, option);
      },
    [navigationCoreModule.push]
  );

  const change = useMemo(
    () =>
      function <T extends INavigationProps>(
        pageType: PageType,
        navigationProps: T,
        option?: NavigationOption
      ) {
        const url = createUrl(
          pageType,
          applicationConfig.applicationId,
          navigationProps
        );
        navigationCoreModule.change(url, option);
      },
    [navigationCoreModule.change]
  );

  const back = useMemo(
    () =>
      function (option?: NavigationOption) {
        navigationCoreModule.back(option);
      },
    [navigationCoreModule.back]
  );

  return {
    push,
    change,
    back,
  };
};

import AnimatedList from "../../../components/animated-list";
import { Item } from "semantic-ui-react";
import MiddleCard from "../../../components/middle-card";
import styled from "styled-components";
import { useToursModel } from "./model";
import ServiceLocator from "../../../../domain/service-locator";

const ToursCardList = () => {
  const model = useToursModel();
  const { theme } = ServiceLocator.ThemeStore;
  return (
    <Wrapper>
      <AnimatedList
        disableAnimation={!model.enableTourListAnimation}
        items={model.tours.map((x, i) => (
          <Item key={`tours-tour-${i}`} onClick={() => model.onClickTour(x.id)}>
            <MiddleCard
              title={x.name}
              imgSrc={x.thumbnailUrl === "" ? "/no-image.png" : x.thumbnailUrl}
              titleColor={theme.color.secondaryBackground}
              gradationColor={theme.color.primaryText}
            />
          </Item>
        ))}
      />
    </Wrapper>
  );
};

export default ToursCardList;

const Wrapper = styled.div`
  margin: 0 ${(props) => props.theme.marginHorizontal};
  height: 100%;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

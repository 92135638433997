import Layout from "../../components/layout";
import AnimatedPageTransition from "../../../domain/module/navigation/animated-page-transition";
import { Areas } from "./areas";

const AreasPage = () => {
  return (
    <Layout
      headerLeftComponents={["back"]}
      backTransitionOption={{ pageInType: "fade", pageOutType: "fade" }}
    >
      <AnimatedPageTransition>
        <Areas />
      </AnimatedPageTransition>
    </Layout>
  );
};

export default AreasPage;

const PauseIcon = (props: { color: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 92 110"
    fill={props.color}
  >
    <g
      id="グループ_378"
      data-name="グループ 378"
      transform="translate(-7652.645 -1726.146)"
    >
      <rect
        id="長方形_27"
        data-name="長方形 27"
        width="37"
        height="110"
        rx="2"
        transform="translate(7652.645 1726.146)"
      />
      <rect
        id="長方形_28"
        data-name="長方形 28"
        width="37"
        height="110"
        rx="2"
        transform="translate(7707.645 1726.146)"
      />
    </g>
  </svg>
);

export default PauseIcon;

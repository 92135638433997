import { useCallback, useState } from "react";
import { BackIcon } from "./back-icon";
import ServiceLocator from "../../../../domain/service-locator";

const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, undefined, 0, undefined];

interface KeypadProps {
  onComplete: () => void;
}

export const Keypad = (props: KeypadProps) => {
  const theme = ServiceLocator.ThemeStore.theme;
  // const [_, setSearchParams] = useSearchParams();
  const guidePlayerModule = ServiceLocator.GuidePlayerModule;
  const playerLayoutModule = ServiceLocator.PlayerLayoutModule;
  const languageRepository = ServiceLocator.LanguageRepository;
  const shouldMarginBottom = guidePlayerModule.currentTrack.watch();
  const miniPlayerHeight =
    playerLayoutModule.playerRectInfo.watch().miniPlayerHeight;

  const localizedEntityRepository = ServiceLocator.LocalizedEntityRepository;
  const { application, tours, guides } =
    localizedEntityRepository.entities.watch();
  const currentLanguageId = languageRepository.currentLanguageId.watch();
  const languages = languageRepository.languages;

  const [num, setNum] = useState<string>("");

  const handleClickOkButton = useCallback(() => {
    const guide = guides.find((x) => x.trackNumber.toString() === num);
    if (!guide) return;

    const currentLanguage = languages.find((x) => x.id === currentLanguageId);
    if (!application || !currentLanguage) return;

    const tour = tours.find((x) => x.id === guide.tourId);
    if (!tour) return;

    window.location.hash = guide.id;

    // const shouldShowPlayer = guidePlayerModule.currentTrack.value === null;
    guidePlayerModule.select(guide.id);
    playerLayoutModule.playerType.setValue("full");
    // setSearchParams((prev) => {
    //   prev.set("guideId", guide.id);
    //   return prev;
    // });
    props.onComplete();
  }, [
    guides,
    languages,
    application,
    tours,
    guidePlayerModule,
    playerLayoutModule.playerType,
    props,
    num,
    currentLanguageId,
  ]);

  return (
    <div className="h-full w-full flex flex-col justify-evenly">
      <div className="text-center">
        聴きたいガイド番号を押して
        <br />
        OKボタンを押してください。
      </div>
      <div className="relative flex items-center justify-center h-24 w-full text-[4rem] text-center font-bold">
        {num.split("").map((x) => (
          <span className="">{x}</span>
        ))}
      </div>
      <div className="w-[80%] mx-auto">
        <div className="relative w-full grid grid-cols-3 gap-y-5">
          {numbers.map((x, i) =>
            x !== undefined ? (
              <div
                className="flex justify-center items-center text-[3rem] font-bold"
                key={`keypad-num-${x}`}
                onClick={() =>
                  setNum((y) => (y.length >= 3 ? y : y + x.toString()))
                }
              >
                {x && x}
              </div>
            ) : i === 11 ? (
              <div
                className="flex items-center justify-center pr-7 pt-2"
                onClick={() => {
                  setNum((x) => (x.length > 0 ? x.slice(0, x.length - 1) : x));
                }}
              >
                <BackIcon color={theme.color.primary} width={45} />
              </div>
            ) : (
              <div />
            )
          )}
        </div>
      </div>
      <div
        className="cursor-pointer bg-[#929598] rounded-full w-fit py-1 px-24 mx-auto font-bold text-2xl mt-5"
        onClick={handleClickOkButton}
      >
        OK
      </div>
      {shouldMarginBottom && (
        <div className="w-full" style={{ height: miniPlayerHeight }} />
      )}
    </div>
  );
};

import { ApiClient } from "@agp/shared.common/infrastructure/api-client";
import { MasterData } from "../../../entity";

export interface ApiGetMasterResponse {
  master: MasterData;
}

export class MasterDataService {
  private client: ApiClient;

  constructor(client: ApiClient) {
    this.client = client;
  }

  public async get(applicationId: string, isPreview?: boolean) {
    return await this.client.send<ApiGetMasterResponse>(
      isPreview === true
        ? `/master/${applicationId}/preview`
        : `/master/${applicationId}`,
      "GET"
    );
  }
}

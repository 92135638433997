import { AudioSpeechClientBase } from "./audio-speech-client-base";

/**
 * Android 用の音声読上クライアント
 * 2024/09/09 SpeechSynthesis が完全実装されていないので、これを考慮した作り
 */
export class AndroidAudioSpeechClient extends AudioSpeechClientBase {
  public play(): void {
    window.speechSynthesis.resume();
    this.isPlaying.setValue(true);
  }

  public pause(): void {
    window.speechSynthesis.pause();
    this.isPlaying.setValue(false);
  }
}

import { useCallback, useEffect, useMemo, useState } from "react";
import { IGuidesModel, StatedGuide } from "./type";
import ServiceLocator from "@agp/user-app/src/domain/service-locator";

const useGuidesModel = (): IGuidesModel => {
  /**
   * Prepare Modules
   */
  const guidePlayerModule = ServiceLocator.GuidePlayerModule;
  const playerLayoutModule = ServiceLocator.PlayerLayoutModule;
  const languageRepository = ServiceLocator.LanguageRepository;
  const applicationStateRepository = ServiceLocator.ApplicationStateRepository;

  const track = guidePlayerModule.currentTrack.watch();
  const isPlaying = guidePlayerModule.isPlaying.watch();
  const currentLanguageId = languageRepository.currentLanguageId.watch();
  const currentTourId = applicationStateRepository.currentTourId.watch();
  const currentGuideId = applicationStateRepository.currentGuideId.watch();
  const listenedGuideIds = applicationStateRepository.listenedGuideIds.watch();
  const localizedEntityRepository = ServiceLocator.LocalizedEntityRepository;

  const { tours, guides } = localizedEntityRepository.entities.watch();

  /**
   * useState
   */
  const [isDisplayAllTourDescription, setIsDisplayAllTourDescription] =
    useState<boolean>(false);

  /**
   * useMemo
   */
  const currentTour = useMemo(
    () => tours.find((x) => x.id === currentTourId),
    [tours, currentTourId]
  );
  const currentGuides = useMemo(
    () => guides.filter((x) => x.tourId === currentTourId),
    [currentTourId, guides]
  );
  const tourThumbnailUrl = useMemo(
    () =>
      !currentTour || currentTour.thumbnailUrl === ""
        ? "/no-image.png"
        : currentTour.thumbnailUrl,
    [currentTour]
  );
  const tourName = useMemo(
    () => (!currentTour || !currentLanguageId ? "" : currentTour.name),
    [currentTour, currentLanguageId]
  );
  const tourDescription = useMemo(
    () => (!currentTour || !currentLanguageId ? "" : currentTour.description),
    [currentTour, currentLanguageId]
  );
  const guideCounts = useMemo(() => currentGuides.length, [currentGuides]);
  const statedGuides = useMemo<StatedGuide[]>(
    () =>
      currentGuides.map((x) => ({
        guide: x,
        isListened: listenedGuideIds.includes(x.id),
      })),
    [currentGuides, listenedGuideIds]
  );
  const isDisplayMiniPlayer = useMemo(() => track !== null, [track]);

  const isCurrentTrackInThisTour = useMemo(
    () => currentGuides.map((x) => x.id).includes(track?.id ?? ""),
    [currentGuides, track]
  );

  const isPlayingCurrentTour = useMemo(
    () => isPlaying && isCurrentTrackInThisTour,
    [isPlaying, isCurrentTrackInThisTour]
  );

  /**
   * useCallback
   */
  const onClickGuide = useCallback(
    (guideId: string) => {
      guidePlayerModule.select(guideId);
      if (!track) playerLayoutModule.playerType.setValue("full");
    },
    [currentTour, track]
  );
  const onClickTourDescription = useCallback(
    () => setIsDisplayAllTourDescription((x) => !x),
    [setIsDisplayAllTourDescription]
  );
  const onChangeTourTitleEnabled = useCallback(
    (isEnabled: boolean) => {
      applicationStateRepository.currentLabel.setValue(
        isEnabled ? tourName : null
      );
    },
    [tourName]
  );

  const onClickPlayOrPauseButton = useCallback(() => {
    if (isPlaying) {
      guidePlayerModule.pause();
      return;
    }

    // 現在開いているガイドの音声を一時停止していた場合のみ、miniPlayerでcurrentTrackの音声を再生する
    // それ以外の場合、libraryの一番最初のガイドをfullPlayerで再生する
    if (isCurrentTrackInThisTour) {
      guidePlayerModule.play();
      return;
    }

    guidePlayerModule.select(currentGuides[0].id);
    playerLayoutModule.playerType.setValue("full");
  }, [isPlayingCurrentTour, currentGuideId, currentGuides]);

  // ページを抜けるときに、ヘッダーのタイトルを消す
  useEffect(
    () => () => applicationStateRepository.currentLabel.setValue(null),
    []
  );

  return {
    tourThumbnailUrl,
    tourName,
    tourDescription,
    guideCounts,
    currentLanguageId,
    guides: statedGuides,
    activeGuideId: track?.id || "",
    isDisplayMiniPlayer,
    isDisplayAllTourDescription,
    isPlayingCurrentTour,
    onClickGuide,
    onClickTourDescription,
    onChangeTourTitleEnabled,
    onClickPlayOrPauseButton,
  };
};

export default useGuidesModel;

import PlayIcon from "./play-icon";
import PauseIcon from "./pause-icon";
import SkipIcon from "./skip-icon";
import { DetailedHTMLProps, HTMLAttributes, useMemo } from "react";
import BackIcon from "./back-icon";
import ListIcon from "./list-icon";
import MapIcon from "./map-icon";
import ArrowRightIcon from "./arrow-right-icon";
import ArrowLeftIcon from "./arrow-left-icon";
import ServiceLocator from "../../../domain/service-locator";

export type IconType =
  | "none"
  | "play"
  | "pause"
  | "skip"
  | "back"
  | "list"
  | "map"
  | "keypad"
  | "arrow left"
  | "arrow right";

export interface IconProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  type: IconType;
  color?: string;
  circleColor?: string;
}

const Icon = (props: IconProps) => {
  const { type, style, ...childProps } = props;
  const { theme } = ServiceLocator.ThemeStore;

  const wrapperStyle = useMemo(
    () => Object.assign({}, { lineHeight: 0 }, style),
    [style]
  );

  return (
    <div {...childProps} style={wrapperStyle}>
      {type === "play" ? (
        <PlayIcon color={props.color || theme.color.primary} />
      ) : type === "pause" ? (
        <PauseIcon color={props.color || theme.color.primary} />
      ) : type === "skip" ? (
        <SkipIcon color={props.color || theme.color.primary} />
      ) : type === "back" ? (
        <BackIcon
          color={props.color || theme.color.secondaryText}
          circleColor={props.circleColor || theme.color.secondaryBackground}
        />
      ) : type === "list" ? (
        <ListIcon
          color={props.color || theme.color.primary}
          circleColor={props.circleColor || theme.color.secondaryBackground}
        />
      ) : type === "map" ? (
        <MapIcon
          color={props.color || theme.color.primary}
          circleColor={props.circleColor || theme.color.secondaryBackground}
        />
      ) : type === "arrow right" ? (
        <ArrowRightIcon color={props.color || theme.color.primary} />
      ) : type === "arrow left" ? (
        <ArrowLeftIcon color={props.color || theme.color.primary} />
      ) : type === "keypad" ? (
        <img src={"/keypad.png"} style={{ width: "100%" }} />
      ) : (
        <></>
      )}
    </div>
  );
};
export default Icon;

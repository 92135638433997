import { useNavigationModule } from "@agp/shared.user-app/application/context/navigation";
import { useCallback, useMemo } from "react";
import { PageType } from "@agp/shared.user-app/application/context/navigation/type";
import ServiceLocator from "../../../../domain/service-locator";

export const useToursModel = () => {
  const { push } = useNavigationModule();
  const { applicationConfig } = ServiceLocator.MasterDataModule.masterData;
  const tours = ServiceLocator.LocalizedEntityRepository.entities.watch().tours;
  const currentAreaId =
    ServiceLocator.ApplicationStateRepository.currentAreaId.watch();

  // --- Memos ---
  const currentTours = useMemo(
    () =>
      !currentAreaId ? [] : tours.filter((x) => x.areaId === currentAreaId),
    [currentAreaId, tours]
  );

  // --- Callbacks ---
  const onClickTour = useCallback(
    (tourId: string) => {
      if (!currentAreaId) return;
      const tour = currentTours.find((x) => x.id === tourId);
      if (!tour) return;
      const nextPage: PageType = tour.mapType === "none" ? "guides" : "map";
      push(
        nextPage,
        {
          areaId: currentAreaId,
          tourId,
        },
        { pageOutType: "slideLeft", pageInType: "slideLeft" }
      );
    },
    [currentAreaId]
  );

  return {
    tours: currentTours,
    enableTourListAnimation: true, // TODO: implement
    onClickTour: onClickTour,
    pageLayoutType: applicationConfig?.toursPageLayoutType,
  };
};

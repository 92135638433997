import { RefObject, useCallback, useRef } from "react";

export interface IGuidesContainerView {
  onScroll: () => void;
  tourNameRef: RefObject<HTMLDivElement>;
}

const useGuidesContainerView = (
  onChangeTourTitleEnabled: (isEnabled: boolean) => void
): IGuidesContainerView => {
  const tourNameRef = useRef<HTMLDivElement>(null);

  const onScroll = useCallback(() => {
    if (!tourNameRef.current) return;
    const rect = tourNameRef.current.getBoundingClientRect();
    if (rect.y + rect.height < 76) onChangeTourTitleEnabled(true);
    else onChangeTourTitleEnabled(false);
  }, [tourNameRef.current]);

  return {
    onScroll,
    tourNameRef,
  };
};

export default useGuidesContainerView;

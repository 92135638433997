import { Fragment } from "react";

interface LinedTextProps {
  text: string;
}

export const LinedText = (props: LinedTextProps) => (
  <>
    {props.text.split("\n").map((x, i) => (
      <Fragment key={i}>
        {x}
        <br />
      </Fragment>
    ))}
  </>
);

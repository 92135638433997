import { Icon, Image } from "semantic-ui-react";
import styled from "styled-components";
import GuideItem from "../guide-item";
import { Divider } from "../../components/divider";
import { Button } from "../../components/button";
import useGuidesContainerView from "./view";
import useGuidesModel from "@agp/shared.user-app/application/model/guides";

const GuidesContainer = () => {
  const model = useGuidesModel();
  const view = useGuidesContainerView(model.onChangeTourTitleEnabled);

  return (
    <Wrapper
      onScroll={view.onScroll}
      paddingBottom={model.isDisplayMiniPlayer ? "70px" : "0px"}
    >
      <TourInfo>
        <Thumbnail src={model.tourThumbnailUrl} />
        <TourName ref={view.tourNameRef}>{model.tourName}</TourName>
        <GuideCounts>
          {model.guideCounts}
          {model.currentLanguageId === "597127b8-726e-4186-97dd-a6f1d94bf368"
            ? "トラック"
            : "Tracks"}
        </GuideCounts>
        <PlayOrPauseButton onClick={() => model.onClickPlayOrPauseButton()}>
          {/*//TODO: LocalizedEntity から表示文言を取得できるようにする*/}
          {model.isPlayingCurrentTour ? (
            <>
              <Icon name={"pause"} />
              {model.currentLanguageId ===
              "597127b8-726e-4186-97dd-a6f1d94bf368"
                ? "一時停止"
                : "Pause"}
            </>
          ) : (
            <>
              <Icon name={"play"} />
              {model.currentLanguageId ===
              "597127b8-726e-4186-97dd-a6f1d94bf368"
                ? "再生"
                : "Play"}
            </>
          )}
        </PlayOrPauseButton>
        <TourDescription
          isClamp={!model.isDisplayAllTourDescription}
          onClick={model.onClickTourDescription}
        >
          {model.tourDescription}
        </TourDescription>
      </TourInfo>
      <Divider />
      <Guides>
        {model.guides.map((x, i) => (
          <GuideItem
            key={`guide-item-${i}`}
            onClickGuide={model.onClickGuide}
            guide={x.guide}
            active={x.guide.id === model.activeGuideId}
            isListened={x.isListened}
          />
        ))}
      </Guides>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ paddingBottom: string }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 ${(props) => props.theme.marginHorizontal};
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  padding-bottom: ${(props) => props.paddingBottom};
`;

const TourInfo = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;

const Thumbnail = styled(Image)`
  width: 70%;
  margin: auto;
  border-radius: 10px;
  overflow: hidden;
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.3));
`;

const TourName = styled.div`
  margin-top: 18px;
  font-size: 2rem;
  color: ${(props) => props.theme.color.primaryText};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const GuideCounts = styled.div`
  font-size: 1.2rem;
  color: ${(props) => props.theme.color.secondaryText};
  margin-top: 2px;
`;

const PlayOrPauseButton = styled(Button)`
  width: 150px;
  height: 28px;
  margin: auto;
  border-radius: 8px;
  margin-top: 7px;
  color: ${(props) => props.theme.color.primaryBackground};
`;

const TourDescription = styled.div<{ isClamp: boolean }>`
  font-size: 1.2rem;
  text-align: left;
  color: ${(props) => props.theme.color.secondaryText};
  margin-top: 8px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  ${(props) => props.isClamp && "-webkit-line-clamp: 3;"}
  cursor: pointer;
`;

const Guides = styled.div`
  margin-top: 0 !important;
  flex-grow: 1;
`;

export default GuidesContainer;

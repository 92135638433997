import { AndroidAudioSpeechClient } from "./android-audio-speech-client";
import { GeneralAudioSpeechClient } from "./general-audio-speech-client";
import { IAudioSpeechClient } from "@agp/shared.user-app/module/guide-player.module/audio-client";

export * from "./general-audio-speech-client";
export * from "./android-audio-speech-client";

export const createSpeechClient = (): IAudioSpeechClient => {
  const userAgent = navigator.userAgent;
  const android = /Android/.test(userAgent);
  return android
    ? new AndroidAudioSpeechClient()
    : new GeneralAudioSpeechClient();
};

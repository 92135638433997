import { Image } from "semantic-ui-react";
import styled from "styled-components";
import Icon from "../../../components/icon";
import ServiceLocator from "../../../../domain/service-locator";

const PlayerMiniComponent = () => {
  const guidePlayerModule = ServiceLocator.GuidePlayerModule;
  const playerLayoutModule = ServiceLocator.PlayerLayoutModule;
  const { theme } = ServiceLocator.ThemeStore;

  const isPlaying = guidePlayerModule.isPlaying.watch();
  const track = guidePlayerModule.currentTrack.watch();

  return (
    <>
      {track && (
        <Wrapper>
          <LeftSegment
            onClick={() => playerLayoutModule.playerType.setValue("full")}
          >
            <ThumbnailWrapper
              onClick={() => playerLayoutModule.playerType.setValue("full")}
            >
              <Image
                src={
                  track.thumbnailUrls && track.thumbnailUrls.length > 0
                    ? track.thumbnailUrls[0]
                    : "no-image.png"
                }
              />
            </ThumbnailWrapper>
            <GuideInfo>
              {/*<TourName>ツアー名</TourName>*/}
              <Title>
                {track.trackNumber}. {track.name}
              </Title>
            </GuideInfo>
          </LeftSegment>
          <RightSegment>
            <IconWrapper
              onClick={(e) => {
                e.stopPropagation();
                guidePlayerModule.playOrPause();
              }}
            >
              <Icon
                type={isPlaying ? "pause" : "play"}
                color={theme.color.primaryText}
              />
            </IconWrapper>
            <IconWrapper
              onClick={(e) => {
                e.stopPropagation();
                guidePlayerModule.next();
              }}
            >
              <Icon type="skip" color={theme.color.primaryText} />
            </IconWrapper>
          </RightSegment>
        </Wrapper>
      )}
    </>
  );
};

export default PlayerMiniComponent;

const height = "40px";

const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  height: ${height};
  margin: 15px ${(props) => props.theme.marginHorizontal};
`;

const LeftSegment = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
`;

const ThumbnailWrapper = styled.div`
  overflow: hidden;
  border-radius: 8px;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1));
  width: ${height};
  min-width: ${height};
`;

const GuideInfo = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

// const TourName = styled.div`
//   font-size: 1.2rem;
//   line-height: 1.4rem;
//   white-space: nowrap;
//   color: ${(props) => props.theme.color.secondaryText};
// `;

const Title = styled.div`
  //margin-top: 0.3rem;
  font-weight: 500;
  font-size: 1.5rem;
  white-space: nowrap;
  color: ${(props) => props.theme.color.primaryText};
`;

const RightSegment = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50px;
  min-width: 50px;
  flex-shrink: 0;
  gap: 20px;
  margin-left: 10px;
`;

const IconWrapper = styled.div`
  width: 15px;
`;

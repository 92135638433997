import { useCallback } from "react";
import { INoteModel } from "./type";
import { useNavigationModule } from "../../context/navigation";

const texts = [
  "音声をお聴きになる際には、イヤホンをご利用ください",
  "館内では他のお客様や展示作品と十分に感覚をあけ、ご利用ください",
];

const useNoteModel = (): INoteModel => {
  const { push } = useNavigationModule();

  // --- Callbacks ---
  const onClick = useCallback(
    () =>
      push(
        "top",
        {},
        {
          pageInType: "fade",
          pageOutType: "fade",
        }
      ),
    []
  );

  return {
    onClick,
    texts,
  };
};

export default useNoteModel;

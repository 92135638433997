import AnimatedPageTransition from "../../../domain/module/navigation/animated-page-transition";
import ServiceLocator from "../../../domain/service-locator";
import { MinpakuKeypad } from "./minpaku";

const KeypadPage = () => {
  const uiTemplateModule = ServiceLocator.UiTemplateModule;

  return (
    <AnimatedPageTransition>
      {uiTemplateModule.uiTemplate === "Minpaku" ? <MinpakuKeypad /> : <div />}
    </AnimatedPageTransition>
  );
};

export default KeypadPage;

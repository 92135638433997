import GuidesContainer from "../../containers/guides";
import Layout from "../../components/layout";
import AnimatedPageTransition from "../../../domain/module/navigation/animated-page-transition";
import { useMemo } from "react";
import ServiceLocator from "../../../domain/service-locator";

const GuidesPage = () => {
  const currentTourId =
    ServiceLocator.ApplicationStateRepository.currentTourId.watch();
  const { tours } = ServiceLocator.LocalizedEntityRepository.entities.watch();
  const mapType = useMemo(
    () => tours.find((x) => x.id === currentTourId)?.mapType ?? "none",
    [tours, currentTourId]
  );

  return (
    <Layout
      headerLeftComponents={["back"]}
      headerRightComponents={mapType !== "none" ? ["map"] : []}
      backTransitionOption={{
        pageOutType: "slideRight",
        pageInType: "slideRight",
      }}
    >
      <AnimatedPageTransition>
        <GuidesContainer />
      </AnimatedPageTransition>
    </Layout>
  );
};

export default GuidesPage;

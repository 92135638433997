const SkipIcon = (props: { color: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 93.574 80.5"
    fill={props.color}
  >
    <g
      id="グループ_379"
      data-name="グループ 379"
      transform="translate(-824 -1000.25)"
    >
      <path
        id="多角形_6"
        data-name="多角形 6"
        d="M38.707,1.869a2,2,0,0,1,3.085,0L77.8,45.5a2,2,0,0,1-1.543,3.273H4.244A2,2,0,0,1,2.7,45.5Z"
        transform="translate(917.574 1000.25) rotate(90)"
      />
      <path
        id="多角形_7"
        data-name="多角形 7"
        d="M38.707,1.869a2,2,0,0,1,3.085,0L77.8,45.5a2,2,0,0,1-1.543,3.273H4.244A2,2,0,0,1,2.7,45.5Z"
        transform="translate(872.771 1000.25) rotate(90)"
      />
    </g>
  </svg>
);

export default SkipIcon;

import { motion } from "framer-motion";
import styled from "styled-components";
import { ReactNode, useEffect, useMemo } from "react";
import { PageTransitionType } from "@agp/shared.user-app/application/context/navigation/type";
import { useNavigationCoreModule } from "../../context/navigation-core";

export interface AnimatedPageTransitionProps {
  pagePushInType?: PageTransitionType;
  pagePushOutType?: PageTransitionType;
  pageBackInType?: PageTransitionType;
  pageBackOutType?: PageTransitionType;
  children?: ReactNode;
}

const duration = 0.2;

const AnimatedPageTransition = (props: AnimatedPageTransitionProps) => {
  const core = useNavigationCoreModule();
  const property = useMemo(
    () =>
      createAnimationProperty(
        core.navigationOption.pageInType!,
        core.navigationOption.pageOutType!
      ),
    [core.navigationOption]
  );

  useEffect(() => {
    if (property) core.onPreparedAnimation();
  }, [property]);

  return (
    <>
      <TransitionRoot {...property} transition={{ duration }}>
        {props.children}
      </TransitionRoot>
    </>
  );
};

const initialDefined: { [key: string]: any } = {
  slideRight: { opacity: 0, x: -100 },
  slideLeft: { opacity: 0, x: 100 },
  fade: { opacity: 0 },
  none: { opacity: 1 },
};

const animateDefined = { opacity: 1, x: 0 };

const exitDefined: { [key: string]: any } = {
  slideRight: { opacity: 0, x: 100 },
  slideLeft: { opacity: 0, x: -100 },
  fade: { opacity: 0 },
  none: { opacity: 1 },
};

const createAnimationProperty = (
  pageInType: PageTransitionType,
  pageOutType: PageTransitionType
): { initial: {}; animate: {}; exit: {} } => {
  const animate = animateDefined;
  const initial = initialDefined[pageInType];
  const exit = exitDefined[pageOutType];

  return {
    animate,
    initial,
    exit,
  };
};

export default AnimatedPageTransition;

const TransitionRoot = styled(motion.div)`
  width: 100%;
  height: 100%;
`;

import ServiceLocator from "../../../domain/service-locator";
import { useNavigationModule } from "@agp/shared.user-app/application/context/navigation";
import { useCallback, useMemo } from "react";

export const useTopModel = () => {
  const localizedEntityRepository = ServiceLocator.LocalizedEntityRepository;
  const languageRepository = ServiceLocator.LanguageRepository;
  const { applicationConfig } = ServiceLocator.MasterDataModule.masterData;

  const currentLanguageId = languageRepository.currentLanguageId.watch();
  const navigation = useNavigationModule();

  /**
   * useMemo
   */
  const entities = localizedEntityRepository.entities.watch();
  const defaultArea = useMemo(
    () => entities.areas.find((x) => x.isDefault),
    [entities]
  );
  const languageDropdownOptions = useMemo(() => {
    if (!entities.application) return [];
    return entities.application.languageIds
      .map((x) => languageRepository.languages.find((y) => y.id === x))
      .filter((x) => x != undefined)
      .map((x) => ({
        id: x!.id,
        text: x!.name,
      }));
  }, [entities]);

  /**
   * useCallback
   */
  const onClickStartButton = useCallback(() => {
    if (!applicationConfig) return;
    switch (applicationConfig.applicationStructureType) {
      case "AreaTop": {
        navigation.push(
          "areas",
          {},
          {
            pageOutType: "fade",
            pageInType: "fade",
          }
        );
        break;
      }
      case "TourTop": {
        if (!defaultArea) return;
        navigation.push(
          "tours",
          { areaId: defaultArea.id },
          {
            pageOutType: "fade",
            pageInType: "fade",
          }
        );
        break;
      }
      case "GuideTop": {
        break;
      }
    }
  }, [applicationConfig, defaultArea]);

  return {
    languageDropdownOptions,
    onClickStartButton,
    currentLanguageId: currentLanguageId || "",
    updateCurrentLanguageId: languageRepository.updateCurrentLanguageId,
  };
};

import AudioPlayerComponent from "./component";
import { AnimatePresence } from "framer-motion";
import ServiceLocator from "../../../domain/service-locator";

const AudioPlayerContainer = () => {
  const currentTrack = ServiceLocator.GuidePlayerModule.currentTrack.watch();
  return (
    <AnimatePresence>
      {currentTrack && (
        <>
          <AudioPlayerComponent />
        </>
      )}
    </AnimatePresence>
  );
};

export default AudioPlayerContainer;

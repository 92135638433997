import { createContext, ReactNode, useContext, useState } from "react";

export interface IUseHeader {
  setTitle: (value: string | undefined) => void;
  title: string | undefined;
}

const HeaderContext = createContext({} as IUseHeader);

export const HeaderProvider = ({ children }: { children: ReactNode }) => {
  const [title, setTitle] = useState<string | undefined>();
  return (
    <HeaderContext.Provider value={{ title, setTitle }}>
      {children}
    </HeaderContext.Provider>
  );
};

export const useHeader = () => useContext(HeaderContext);

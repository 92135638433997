const BackIcon = (props: { color: string; circleColor: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79 79">
    <g
      id="グループ_602"
      data-name="グループ 602"
      transform="translate(-4563.76 -2417.832)"
    >
      <g id="戻るボタン" transform="translate(4563.76 2417.832)">
        <circle
          id="楕円形_2"
          data-name="楕円形 2"
          cx="39.5"
          cy="39.5"
          r="39.5"
          fill={props.circleColor}
        />
        <g
          id="グループ_4"
          data-name="グループ 4"
          transform="translate(44.377 22.349) rotate(90)"
          style={{
            mixBlendMode: "normal",
            isolation: "isolate",
          }}
        >
          <line
            id="線_1"
            data-name="線 1"
            x2="17.151"
            y2="15.755"
            transform="translate(0 0)"
            stroke={props.color}
            strokeLinecap="round"
            strokeWidth="8"
          />
          <line
            id="線_2"
            data-name="線 2"
            x1="17.151"
            y2="15.646"
            transform="translate(17.151 0)"
            stroke={props.color}
            strokeLinecap="round"
            strokeWidth="8"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default BackIcon;

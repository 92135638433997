import { MasterDataService } from "./services/master-data.service";
import { ApiModuleBase } from "@agp/shared.common/domain/api.module";
import { ApiClient } from "@agp/shared.common/infrastructure/api-client";
import { JsonContentTypeFilter } from "@agp/shared.common/domain/api.module/filters/json-content-type.filter";

export interface IUserAppApiServices {
  masterDataService: MasterDataService;
}

export class UserAppApiModule extends ApiModuleBase<IUserAppApiServices> {
  protected configureClient(client: ApiClient): void {
    client.AddFilter(new JsonContentTypeFilter());
  }

  protected registerServices(client: ApiClient): IUserAppApiServices {
    return { masterDataService: new MasterDataService(client) };
  }
}

import Router from "./router";
import AudioPlayerContainer from "./presentation/containers/player";
import React, { useState } from "react";
import { motion } from "framer-motion";
import ServiceLocator from "./domain/service-locator";

const AppContainer = () => {
  const playerType = ServiceLocator.PlayerLayoutModule.playerType.watch();
  const theme = ServiceLocator.ThemeStore.theme;
  const [showDeepBackground, setShowDeepBackground] = useState<boolean>(false);

  return (
    <>
      {showDeepBackground && <div className="absolute inset-0 bg-black" />}
      <motion.div
        className="overflow-hidden h-[calc(var(--vh,1vh)*100)]"
        style={{
          background: theme.color.primaryBackground,
        }}
        animate={{
          scale: playerType === "full" ? 0.9 : 1,
          y: playerType === "full" ? "-4%" : 0,
          borderRadius: playerType == "full" ? 20 : 0,
        }}
        transition={{
          type: "tween",
          duration: 0.3,
        }}
        onAnimationStart={() =>
          playerType === "full" && setShowDeepBackground(true)
        }
        onAnimationComplete={() =>
          playerType !== "full" && setShowDeepBackground(false)
        }
      >
        <Router />
        <motion.div
          animate={{
            opacity: playerType === "full" ? 0.5 : 0,
          }}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 3000,
            pointerEvents: "none",
            background: theme.color.secondaryBackground,
          }}
        />
      </motion.div>
      <AudioPlayerContainer />
    </>
  );
};

export default AppContainer;

import {
  checkSupportPreload,
  createPrefetchState,
  loadAndCacheImages,
} from "./helper";
import {
  MasterData,
  MultilingualArea,
  MultilingualGuide,
  MultilingualTour,
} from "@agp/shared.user-app/entity";
import {
  IReadOnlyReactiveProperty,
  ReactivePropertyInstance,
} from "@agp/shared.common/use-reactive-property";
import { PrefetchState, PrefetchType } from "./type";

export class PrefetchModule {
  private _state = new ReactivePropertyInstance<PrefetchState>(
    createPrefetchState()
  );
  public get state(): IReadOnlyReactiveProperty<PrefetchState> {
    return this._state;
  }

  public fetchAsync = async (masterData: MasterData) => {
    if (!checkSupportPreload()) return;
    await Promise.all([
      this.fetchTopImageAsync(),
      this.fetchAreaThumbnailsAsync(masterData.areas),
      this.fetchTourThumbnailsAsync(masterData.tours),
      this.fetchGuideThumbnailsAsync(masterData.guides),
      this.fetchTourLocalMapAsync(masterData.tours),
    ]);
  };

  private fetchTopImageAsync = () =>
    this.fetchImages(["/samples/top.png"], PrefetchType.topImage);

  private fetchAreaThumbnailsAsync = (areas: MultilingualArea[]) =>
    this.fetchImages(
      areas.map((x) => x.thumbnailUrl),
      PrefetchType.areaThumbnails
    );

  private fetchTourThumbnailsAsync = (tours: MultilingualTour[]) =>
    this.fetchImages(
      tours.map((x) => x.thumbnailUrl),
      PrefetchType.tourThumbnails
    );

  private fetchGuideThumbnailsAsync = (guides: MultilingualGuide[]) =>
    this.fetchImages(
      guides.reduce((acc, cur) => {
        cur.thumbnailUrls.forEach((x) => acc.push(x));
        return acc;
      }, [] as string[]),
      PrefetchType.guideThumbnails
    );

  private fetchTourLocalMapAsync = async (tours: MultilingualTour[]) => {
    const localMapTours = tours.filter((x) => x.mapType === "local");
    if (localMapTours.length > 0) {
      await this.fetchImages(
        localMapTours.map((x) => x.localMap.mapUrl),
        PrefetchType.tourLocalMaps
      );
    }
  };

  private fetchImages = async (urls: string[], prefetchType: PrefetchType) => {
    await loadAndCacheImages(urls);
    const newState: { [key: string]: boolean } = {};
    newState[prefetchType] = true;
    this._state.setValue((x) => Object.assign({}, x, newState));
  };
}

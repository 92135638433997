import { AudioSpeechClientBase } from "./audio-speech-client-base";

/**
 * SpeechSynthesisAPI が完全じっそうされているブラウザ用
 */
export class GeneralAudioSpeechClient extends AudioSpeechClientBase {
  public play(): void {
    window.speechSynthesis.resume();
  }

  public pause(): void {
    window.speechSynthesis.pause();
  }
}

import GradatedImage from "../gradated-image";
import styled from "styled-components";

export interface MiddleCardProps {
  title: string;
  imgSrc: string;
  titleColor: string;
  gradationColor: string;
  height?: string;
}

const MiddleCard = (props: MiddleCardProps) => (
  <Wrapper>
    <GradatedImage
      rangeRate={0.4}
      src={props.imgSrc}
      color={props.gradationColor}
    />
    <Title color={props.titleColor}>{props.title}</Title>
  </Wrapper>
);

export default MiddleCard;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 20px;
`;

const Title = styled.div<{ color: string }>`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  color: ${(props) => props.color};
  font-size: 2.5rem;
  height: 3rem;
  margin-bottom: 15px;
  margin-left: 20px;
  margin-right: 20px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

import React, { ReactNode } from "react";
import { Modal as SemanticModal } from "semantic-ui-react";

type Props = {
  children?: ReactNode;
  title?: string;
  isModalOpen: boolean;
  onClose: () => void;
  width?: string;
};

const Modal = ({ children, isModalOpen, onClose, title, width }: Props) => {
  return (
    <SemanticModal open={isModalOpen} onClose={onClose} style={{ width }}>
      {title && <SemanticModal.Header>{title}</SemanticModal.Header>}
      <SemanticModal.Content>{children}</SemanticModal.Content>
    </SemanticModal>
  );
};

export default Modal;

import { Item } from "semantic-ui-react";
import { useNavigationModule } from "@agp/shared.user-app/application/context/navigation";
import ServiceLocator from "../../../domain/service-locator";
import { useCallback } from "react";

export const Areas = () => {
  const navigation = useNavigationModule();
  const areas = ServiceLocator.LocalizedEntityRepository.entities.watch().areas;

  // --- Callbacks---
  const onClickArea = useCallback((areaId: string) => {
    navigation.push(
      "tours",
      { areaId },
      { pageInType: "slideLeft", pageOutType: "slideLeft" }
    );
  }, []);
  return (
    <div>
      <Item.Group unstackable divided>
        {areas!.map((x, i) => (
          <Item key={`areas-area-${i}`} onClick={() => onClickArea(x.id)}>
            <Item.Image
              src={x.thumbnailUrl == "" ? "/no-image.png" : x.thumbnailUrl}
            />
            <Item.Content>
              <Item.Header>{x.name}</Item.Header>
              <Item.Description>{x.description}</Item.Description>
            </Item.Content>
          </Item>
        ))}
      </Item.Group>
    </div>
  );
};

import ToursCardList from "./tours-card-list";
import ToursList from "./tours-list";
import ServiceLocator from "../../../../domain/service-locator";
import Layout from "../../../components/layout";

export const ToursGeneral = () => {
  const { applicationConfig } = ServiceLocator.MasterDataModule.masterData;
  return (
    <Layout
      headerLeftComponents={["back"]}
      headerRightComponents={[]}
      backTransitionOption={{
        pageInType: "fade",
        pageOutType: "fade",
      }}
    >
      {applicationConfig?.toursPageLayoutType === "Card" ? (
        <ToursCardList />
      ) : (
        <ToursList />
      )}
    </Layout>
  );
};

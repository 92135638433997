import NoteContainer from "../../containers/note";
import AnimatedPageTransition from "../../../domain/module/navigation/animated-page-transition";
import ServiceLocator from "../../../domain/service-locator";
import { MinpakuNote } from "./minpaku";

const NotePage = () => {
  const uiTemplateModule = ServiceLocator.UiTemplateModule;

  return (
    <AnimatedPageTransition>
      {uiTemplateModule.uiTemplate === "Minpaku" ? (
        <MinpakuNote />
      ) : (
        <NoteContainer />
      )}
    </AnimatedPageTransition>
  );
};

export default NotePage;

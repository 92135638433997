import { motion, Variants } from "framer-motion";
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import Icon from "../icon";

export interface DropdownProps {
  label: string;
  options: DropdownOption[];
  selectedId?: string | null;
  onChange: (id: string) => void;
}

export interface DropdownOption {
  id: string;
  text: string;
}

const itemVariants: Variants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 300, damping: 24 },
  },
  closed: { opacity: 0, y: 20, transition: { duration: 0.2 } },
};

const Dropdown = (props: DropdownProps) => {
  const { label, options, selectedId, onChange } = props;
  const [isOpenList, setIsOpenList] = useState<boolean>(false);
  const [optionListMaxHeight, setOptionListMaxHeight] = useState<number>();
  const optionListRef = useRef<HTMLUListElement>(null);

  const selectedOption = useMemo(() => {
    const selected = options.find((x) => x.id === selectedId);
    if (selected) return selected;
    if (options.length > 0) return options[0];
    return undefined;
  }, [options, selectedId]);

  const onClickItem = useCallback(
    (id: string) => {
      onChange(id);
      setIsOpenList(false);
    },
    [onChange]
  );

  useLayoutEffect(() => {
    if (window == undefined) return;
    if (optionListRef.current == undefined) return;
    const rect = optionListRef.current.getBoundingClientRect();
    const positionY = rect.y;
    setOptionListMaxHeight(window.innerHeight - positionY);
  }, [optionListRef]);

  return (
    <motion.nav initial={false} animate={isOpenList ? "open" : "closed"}>
      <Label>{label}</Label>
      <Selector
        whileTap={{ scale: 0.97 }}
        onClick={() => setIsOpenList(!isOpenList)}
      >
        <SelectorText>{selectedOption?.text}</SelectorText>
        <motion.div
          variants={{
            open: { rotate: 270 },
            closed: { rotate: 90 },
          }}
          transition={{ duration: 0.2 }}
        >
          <Icon type="arrow right" style={{ width: 8 }} color="#ffffff" />
        </motion.div>
      </Selector>
      <OptionListWrapper style={{ position: "relative", width: "100%" }}>
        <OptionList
          ref={optionListRef}
          variants={{
            open: {
              clipPath: "inset(0% 0% 0% 0% round 10px)",
              transition: {
                type: "spring",
                bounce: 0,
                duration: 0.3,
                delayChildren: 0.1,
                staggerChildren: 0.05,
              },
            },
            closed: {
              clipPath: "inset(10% 50% 90% 50% round 10px)",
              transition: {
                type: "spring",
                bounce: 0,
                duration: 0.3,
              },
            },
          }}
          style={{
            pointerEvents: isOpenList ? "auto" : "none",
            maxHeight: optionListMaxHeight,
          }}
        >
          {options.map((x, i) => (
            <OptionItem
              key={`language-dropdown-${i}`}
              isActive={x.id === selectedId}
              onClick={() => onClickItem(x.id)}
              variants={itemVariants}
            >
              {x.text}
            </OptionItem>
          ))}
        </OptionList>
      </OptionListWrapper>
    </motion.nav>
  );
};

export default Dropdown;

const Label = styled.div`
  color: ${(props) => props.theme.color.secondaryText};
  margin-left: 12px;
`;

const Selector = styled(motion.div)`
  border-bottom: 1px solid ${(props) => props.theme.color.secondaryText};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0 12px;
`;

const SelectorText = styled.div`
  color: ${(props) => props.theme.color.secondary};
  font-size: 2rem;
  line-height: 4rem;
`;

const OptionListWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const OptionList = styled(motion.ul)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: ${(props) => props.theme.color.secondaryBackground};
  border-radius: 10px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px 0;
`;

const OptionItem = styled(motion.li)<{ isActive?: boolean }>`
  font-size: 2rem;
  line-height: 4rem;
  font-weight: bold;
  padding: 5px 12px;
  color: ${(props) => props.theme.color.primaryText};
  ${(props) => props.isActive && "background: " + props.theme.color.secondary}
`;

import React, { useEffect, useState } from "react";
import App from "./app";
import { AnimatePresence, motion } from "framer-motion";
import Loading from "./presentation/components/loading";
import ServiceLocator from "./domain/service-locator";

let ignore = false;
export const Boot = () => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  useEffect(() => {
    const initialize = async () => {
      if (ignore) return;
      await ServiceLocator.initializeAsync();
      setIsInitialized(true);
    };
    initialize();
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <AnimatePresence>
      {isInitialized ? (
        <App />
      ) : (
        <motion.div
          key="loading"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Loading />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

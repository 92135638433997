import styled from "styled-components";
import { Icon as SurIcon } from "semantic-ui-react";
import useNoteModel from "@agp/shared.user-app/application/model/note";

const NoteContainer = () => {
  const model = useNoteModel();
  return (
    <Wrapper onClick={model.onClick}>
      {model.texts.map((x, i) => (
        <Segment key={i}>
          <Icon name="circle" size="large" />
          <Text>{x}</Text>
        </Segment>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: ${window?.innerHeight + "px" ?? "100vh"};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Segment = styled.div`
  margin: 20px 10px;
  display: flex;
  gap: 5px;
`;

const Icon = styled(SurIcon)`
  line-height: 32px !important;
`;

const Text = styled.div`
  font-size: 20px;
  display: inline-block;
  line-height: 32px;
`;

export default NoteContainer;

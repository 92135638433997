import ServiceLocator from "../../../domain/service-locator";
import { LocalMap } from "./local-map";
import React, { useMemo } from "react";
import Layout from "../../components/layout";

export const TourLocalMap = () => {
  /**
   * Prepare Modules
   */
  const localizedEntityRepository = ServiceLocator.LocalizedEntityRepository;
  const applicationStateRepository = ServiceLocator.ApplicationStateRepository;

  /**
   * ReactiveProperty
   */
  const currentTourId = applicationStateRepository.currentTourId.watch();
  const entities = localizedEntityRepository.entities.watch();
  const guides = useMemo(
    () => entities.guides.filter((x) => x.tourId === currentTourId),
    [currentTourId, entities]
  );

  const mapImageUrl =
    applicationStateRepository.currentTour.watch()?.localMap.mapUrl;

  // console.log(
  //   applicationStateRepository.currentTourId.value,
  //   applicationStateRepository.currentTour.value,
  //   mapImageUrl,
  //   guides
  // );

  return mapImageUrl ? (
    <Layout
      headerLeftComponents={["back"]}
      headerRightComponents={["guideList"]}
      backTransitionOption={{
        pageOutType: "slideRight",
        pageInType: "slideRight",
      }}
    >
      <LocalMap guides={guides} mapImageUrl={mapImageUrl} />
    </Layout>
  ) : (
    <></>
  );
};

import { getQuery } from "../query";

export interface IPreviewConfig {
  isPreview: boolean;
  previewTheme: PreviewTheme;
}

interface PreviewTheme {
  primaryColor?: string;
  secondaryColor?: string;
  primaryTextColor?: string;
  secondaryTextColor?: string;
  primaryBackgroundColor?: string;
  secondaryBackgroundColor?: string;
}

const addSharp = (text: string) => `#${text}`;

export const getPreviewTheme = (): PreviewTheme => {
  const primaryColor = getQuery("primaryColor");
  const secondaryColor = getQuery("secondaryColor");
  const primaryTextColor = getQuery("primaryTextColor");
  const secondaryTextColor = getQuery("secondaryTextColor");
  const primaryBackgroundColor = getQuery("primaryBackgroundColor");
  const secondaryBackgroundColor = getQuery("secondaryBackgroundColor");

  const previewTheme: { [key: string]: string } = {};

  if (primaryColor) previewTheme["primaryColor"] = addSharp(primaryColor);
  if (secondaryColor) previewTheme["secondaryColor"] = addSharp(secondaryColor);
  if (primaryTextColor)
    previewTheme["primaryTextColor"] = addSharp(primaryTextColor);
  if (secondaryTextColor)
    previewTheme["secondaryTextColor"] = addSharp(secondaryTextColor);
  if (primaryBackgroundColor)
    previewTheme["primaryBackgroundColor"] = addSharp(primaryBackgroundColor);
  if (secondaryBackgroundColor)
    previewTheme["secondaryBackgroundColor"] = addSharp(
      secondaryBackgroundColor
    );

  return previewTheme as PreviewTheme;
};

export const previewConfig = {
  isPreview: getQuery("isPreview") === "true",
  previewTheme: getPreviewTheme(),
};

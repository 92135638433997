import { getQuery } from "../query";
import { IApplicationConfig } from "@agp/shared.user-app/infrastructure/application-config";

const apiUrl = import.meta.env.VITE_API_URL;

const getApplicationId = () => {
  const value = getQuery("applicationId");
  if (!value) throw new Error();
  return value;
};

export const applicationConfig: IApplicationConfig = {
  apiUrl,
  applicationId: getApplicationId(),
};

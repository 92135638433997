import AnimatedPageTransition from "../../../domain/module/navigation/animated-page-transition";
import { useMemo } from "react";
import { TTemplate } from "../../../domain/entities/template";
import ServiceLocator from "../../../domain/service-locator";
import { ToursAcoustiguide } from "./acoustiguide";
import { ToursGeneral } from "./general";

const ToursPage = () => {
  const localizedEntityRepository = ServiceLocator.LocalizedEntityRepository;
  const application = localizedEntityRepository.entities.watch().application;

  const template = useMemo<TTemplate>(
    () =>
      application?.id === "08dcc024-e8ac-4fc3-81bd-b80fd82d7f83"
        ? "Acoustiguide"
        : "General",
    [application]
  );

  return (
    <AnimatedPageTransition>
      <div className="overflow-y-scroll hidden-scrollbar w-full h-full">
        {template === "Acoustiguide" ? <ToursAcoustiguide /> : <ToursGeneral />}
      </div>
    </AnimatedPageTransition>
  );
};

export default ToursPage;

import { createContext, useContext } from "react";
import { NavigationOption, PageType } from "./type";

export interface INavigationProps {}

export interface INavigationModule {
  push: <T extends INavigationProps>(
    page: PageType,
    props: T,
    option?: NavigationOption
  ) => void;
  change: <T extends INavigationProps>(
    page: PageType,
    props: T,
    option?: NavigationOption
  ) => void;
  back: (option?: NavigationOption) => void;
}

const NavigationModuleContext = createContext({} as INavigationModule);

export const NavigationModuleProvider = (props: {
  children: any;
  module: INavigationModule;
}) => {
  return (
    <NavigationModuleContext.Provider value={props.module}>
      {props.children}
    </NavigationModuleContext.Provider>
  );
};

export const useNavigationModule = () => useContext(NavigationModuleContext);

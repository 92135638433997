import { useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import {
  NavigationOption,
  NavigationType,
} from "@agp/shared.user-app/application/context/navigation/type";

export const useNavigationCoreModule = () => {
  const navigate = useNavigate();
  const [navigateType, setNavigateType] = useState<NavigationType>();
  const [isAnimationPrepared, setIsAnimationPrepared] = useState<boolean>();
  const [url, setUrl] = useState<string>();
  const [navigationOption, setNavigationOption] = useState<NavigationOption>({
    pageInType: "none",
    pageOutType: "none",
  });

  const push = useCallback(
    (url: string, option?: NavigationOption) => {
      setNavigateType("push");
      setIsAnimationPrepared(false);
      setUrl(url);
      setNavigationOption(
        Object.assign({ pageInType: "none", pageOutType: "none" }, option)
      );
    },
    [navigate]
  );

  const change = useCallback(
    (url: string, option?: NavigationOption) => {
      setNavigateType("change");
      setIsAnimationPrepared(false);
      setUrl(url);
      setNavigationOption(
        Object.assign({ pageInType: "none", pageOutType: "none" }, option)
      );
    },
    [navigate]
  );

  const back = useCallback(
    (option?: NavigationOption) => {
      setNavigateType("back");
      setIsAnimationPrepared(false);
      setNavigationOption(
        Object.assign({ pageInType: "none", pageOutType: "none" }, option)
      );
    },
    [navigate]
  );

  const onPreparedAnimation = () => {
    if (isAnimationPrepared) return;
    if (navigateType !== "back" && !url) return;
    setIsAnimationPrepared(true);
    navigateType === "back"
      ? navigate(-1)
      : navigateType === "push"
      ? navigate(url!)
      : navigate(url!, { replace: true });
  };
  return {
    push,
    change,
    back,
    onPreparedAnimation,
    navigationOption,
    previousNavigateType: navigateType,
  };
};

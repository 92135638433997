const PlayIcon = (props: { color: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 93.539 111.066"
    fill={props.color}
  >
    <path
      id="多角形_20"
      data-name="多角形 20"
      d="M53.813,2.9a2,2,0,0,1,3.44,0l52.02,87.621a2,2,0,0,1-1.72,3.021H3.513a2,2,0,0,1-1.72-3.021Z"
      transform="translate(93.539) rotate(90)"
    />
  </svg>
);

export default PlayIcon;

import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import ServiceLocator from "../../service-locator";

export const useQueryModule = () => {
  const [searchParams, _] = useSearchParams();
  const applicationStateRepository = ServiceLocator.ApplicationStateRepository;
  const languageRepository = ServiceLocator.LanguageRepository;

  useEffect(() => {
    const languageId = searchParams.get("languageId");
    if (languageId) languageRepository.updateCurrentLanguageId(languageId);

    const areaId = searchParams.get("areaId");
    if (areaId) applicationStateRepository.currentAreaId.setValue(areaId);

    const tourId = searchParams.get("tourId");
    if (tourId) applicationStateRepository.currentTourId.setValue(tourId);

    const guideId = searchParams.get("guideId");
    if (guideId) applicationStateRepository.currentGuideId.setValue(guideId);
  }, [
    searchParams,
    languageRepository.updateCurrentLanguageId,
    applicationStateRepository,
    languageRepository,
  ]);
};

import ServiceLocator from "../../../../../domain/service-locator";
import Icon from "../../../../components/icon";
import styled from "styled-components";
import { MinpakuSeekBar } from "./minpaku-seek-bar";

const MinpakuPlayerMiniComponent = () => {
  const guidePlayerModule = ServiceLocator.GuidePlayerModule;
  const playerLayoutModule = ServiceLocator.PlayerLayoutModule;
  const { theme } = ServiceLocator.ThemeStore;

  const isPlaying = guidePlayerModule.isPlaying.watch();
  const track = guidePlayerModule.currentTrack.watch();

  return (
    <>
      {track && (
        <Wrapper>
          <div className="flex justify-between flex-grow items-center">
            <LeftSegment
              onClick={() => {
                playerLayoutModule.playerType.setValue("full");
              }}
            >
              <ThumbnailWrapper
                onClick={() => {
                  playerLayoutModule.playerType.setValue("full");
                }}
              >
                <div className="flex justify-center items-center h-full aspect-[4/3] bg-black text-white font-bold text-[1.5rem]">
                  {track.trackNumber}
                </div>
              </ThumbnailWrapper>
              <GuideInfo className="text-md">
                {/*<TourName>ツアー名</TourName>*/}
                <Title>
                  {track.trackNumber}. {track.name}
                </Title>
              </GuideInfo>
            </LeftSegment>
            <RightSegment>
              <IconWrapper
                onClick={(e) => {
                  e.stopPropagation();
                  guidePlayerModule.playOrPause();
                }}
              >
                <Icon
                  type={isPlaying ? "pause" : "play"}
                  color={theme.color.primaryText}
                />
              </IconWrapper>
              <IconWrapper
                onClick={(e) => {
                  e.stopPropagation();
                  guidePlayerModule.stop();
                }}
              >
                {/*<Icon type="skip" color={theme.color.primaryText} />*/}
                <img src="/minpaku-demo/icons/close.svg" className="w-full" />
              </IconWrapper>
            </RightSegment>
          </div>
          <MinpakuSeekBar withTime={false} />
        </Wrapper>
      )}
    </>
  );
};

export default MinpakuPlayerMiniComponent;

const Wrapper = styled.div`
  align-items: stretch;
  height: 70px;
  background: #b8b2b8;
  padding: 2px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
`;

const LeftSegment = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  flex-grow: 1;
  height: 30px;
  overflow: hidden;
`;

const ThumbnailWrapper = styled.div`
  overflow: hidden;
  border-radius: 8px;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1));
  width: 50px;
`;

const GuideInfo = styled.div`
  height: 100%;
  display: flex;
  align-items: end;
`;

// const TourName = styled.div`
//   font-size: 1.2rem;
//   line-height: 1.4rem;
//   white-space: nowrap;
//   color: ${(props) => props.theme.color.secondaryText};
// `;

const Title = styled.div`
  //margin-top: 0.3rem;
  white-space: nowrap;
  color: ${(props) => props.theme.color.primaryText};
`;

const RightSegment = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 60px;
  min-width: 50px;
  flex-shrink: 0;
  gap: 20px;
  margin-left: 10px;
`;

const IconWrapper = styled.div`
  width: 20px;
`;

import { ApiClient } from "../../infrastructure/api-client";

export abstract class ApiModuleBase<TServices> {
  public readonly services: TServices;

  public constructor(endpoint: string) {
    const apiClient = new ApiClient(endpoint);
    this.configureClient(apiClient);
    this.services = this.registerServices(apiClient);
  }

  protected abstract configureClient(client: ApiClient): void;

  protected abstract registerServices(client: ApiClient): TServices;
}

import styled from "styled-components";
import Icon from "../../../components/icon";
import { useMemo } from "react";
import { Image } from "semantic-ui-react";
import "@agp/shared.common/extensions/number.extensions";
import { LinedText } from "../../../components/lined-text";
import { TTemplate } from "../../../../domain/entities/template";
import ServiceLocator from "../../../../domain/service-locator";
import { IAudioSpeechTrack } from "@agp/shared.user-app/module/guide-player.module/audio-client";

const barHeight = 3;
const barMargin = 10;

const PlayerMiddleComponent = () => {
  const guidePlayerModule = ServiceLocator.GuidePlayerModule;
  const playerLayoutModule = ServiceLocator.PlayerLayoutModule;

  const track = guidePlayerModule.currentTrack.watch();
  const playerRectInfo = playerLayoutModule.playerRectInfo.watch();
  const upperSegmentHeight = useMemo(
    () => playerRectInfo.middlePlayerHeight - barMargin * 2 - barHeight,
    [playerRectInfo]
  );
  const playerType = playerLayoutModule.playerType.watch();

  // 以下、民博デモ用実装. 後々整理
  const application =
    ServiceLocator.LocalizedEntityRepository.entities.watch().application;
  const template = useMemo<TTemplate>(
    () =>
      application?.id === "08dcc024-e8ac-4fc3-81bd-b80fd82d7f83"
        ? "Acoustiguide"
        : "General",
    [application]
  );

  return (
    <>
      {track && (
        <Wrapper
          id="MiddlePlayer"
          isScrollable={playerType === "full"}
          // onScroll={(e: any) => onScrollTopChanged(e.target.scrollTop)}
        >
          <Bar onClick={() => playerLayoutModule.playerType.setValue("mini")} />
          <UpperSegment
            height={upperSegmentHeight}
            onClick={() => playerLayoutModule.playerType.setValue("mini")}
          >
            {/*<ThumbnailWrapper>*/}
            {template === "Acoustiguide" ? (
              <LargeThumbnail
                height={upperSegmentHeight * 0.5}
                imageUrl={
                  track.thumbnailUrls && track.thumbnailUrls.length > 0
                    ? track.thumbnailUrls[0]
                    : "no-image.png"
                }
              />
            ) : (
              <Thumbnail
                src={
                  track.thumbnailUrls && track.thumbnailUrls.length > 0
                    ? track.thumbnailUrls[0]
                    : "no-image.png"
                }
              />
            )}
            {/*</ThumbnailWrapper>*/}
            <GuideInfo>
              <Title>
                {`${track.trackNumber.toPadString(2)}. ${track.name}`}
              </Title>
              <TourNameWrapper>
                <TourName>{track.artist}</TourName>
              </TourNameWrapper>
              <TextResizeButton onClick={(e) => e.stopPropagation()} />
            </GuideInfo>
            <Controller />
          </UpperSegment>
          <Description />
        </Wrapper>
      )}
    </>
  );
};

export default PlayerMiddleComponent;

const Controller = () => {
  const guidePlayerModule = ServiceLocator.GuidePlayerModule;
  const isPlaying = guidePlayerModule.isPlaying.watch();
  // TODO: Disable を実装
  // const canNext = guidePlayerModule.canNext.watch();
  // const canPrev = guidePlayerModule.canPrev.watch();

  return (
    <ControllerWrapper>
      <MiddleIcon
        type={"skip"}
        reversed
        onClick={(e: any) => {
          e.stopPropagation();
          guidePlayerModule.prev();
        }}
      />
      <BigIcon
        type={isPlaying ? "pause" : "play"}
        onClick={(e: any) => {
          e.stopPropagation();
          guidePlayerModule.playOrPause();
        }}
      />
      <MiddleIcon
        type={"skip"}
        onClick={(e: any) => {
          e.stopPropagation();
          guidePlayerModule.next();
        }}
      />
    </ControllerWrapper>
  );
};

const Description = () => {
  const guidePlayerModule = ServiceLocator.GuidePlayerModule;
  const playerLayoutModule = ServiceLocator.PlayerLayoutModule;
  const { theme } = ServiceLocator.ThemeStore;

  const playerMode = guidePlayerModule.playerMode.watch();
  const track = guidePlayerModule.currentTrack.watch() as IAudioSpeechTrack;
  const currentSeek = guidePlayerModule.currentSeek.watch();
  const playerRectInfo = playerLayoutModule.playerRectInfo.watch();

  const descriptionWrapperHeight = useMemo(
    () => playerRectInfo.fullPlayerHeight - playerRectInfo.middlePlayerHeight,
    [playerRectInfo]
  );

  return playerMode === "AudioSynthesis" ? (
    <DescriptionWrapper height={descriptionWrapperHeight}>
      {[...track.text].map((x, i) => (
        <span
          style={{
            color:
              i < currentSeek
                ? theme.color.primaryText
                : theme.color.secondaryText,
          }}
          key={`sentence-${i}`}
          onClick={() => guidePlayerModule.seek(i)}
        >
          {x === "\n" ? <br /> : x}
        </span>
      ))}
      <Margin height={30} />
    </DescriptionWrapper>
  ) : (
    <DescriptionWrapper height={descriptionWrapperHeight}>
      <LinedText text={track.text} />
      <Margin height={30} />
    </DescriptionWrapper>
  );
};

const Wrapper = styled.div<{ isScrollable: boolean }>`
  padding-left: 25px;
  padding-right: 25px;
  will-change: transform;
  ${(props) => props.isScrollable && "overflow-y: scroll;"}
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  overscroll-behavior: none;
`;

const Bar = styled.div`
  margin: ${barMargin}px auto;
  background: ${(props) => props.theme.color.secondaryText};
  height: ${barHeight}px;
  width: 30px;
  border-radius: 3px;
`;

const UpperSegment = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding-top: 20px;
  position: relative;
`;

// const ThumbnailWrapper = styled.div`
//   //filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.3));
//   margin: auto;
//   padding: 20px 0;
// `;

const Thumbnail = styled(Image)`
  overflow: hidden;
  border-radius: 20px;
  width: 50vw;
  height: 50vw;
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.3));
`;

const LargeThumbnail = styled.div<{ height: number; imageUrl: string }>`
  border-radius: 5px;
  width: 105%;
  height: ${(props) => props.height}px;
  background: url(${(props) => props.imageUrl}) center;
  background-size: contain;
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.3));
  margin-bottom: 10px;
`;

const GuideInfo = styled.div`
  text-align: center;
  width: 100%;
  position: relative;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 2rem;
  line-height: 28px;
  color: ${(props) => props.theme.color.primaryText};
`;

const TourNameWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 28px;
  gap: 10px;
`;

const TextResizeButton = styled.div`
  cursor: pointer;
  position: absolute;
  right: 5px;
  background-image: url("/text-resize.png");
  background-size: cover;
  background-position: center;
  aspect-ratio: 1;
  top: 0;
  bottom: 0;
`;

const TourName = styled.div`
  font-size: 1.6rem;
  color: ${(props) => props.theme.color.primary};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ControllerWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 30px;
  width: 100%;
`;

const BigIcon = styled(Icon)`
  width: 32px;
`;

const MiddleIcon = styled(Icon)<{ reversed?: boolean }>`
  width: 32px;
  ${(props) => props.reversed && "transform:rotate(180deg);"}
`;

const DescriptionWrapper = styled.div<{ height: number }>`
  padding-top: 10px;
  line-height: 24px;
  font-size: 1.6rem;
  cursor: default;
  // background: ${(props) => props.theme.color.primaryBackground};
  height: ${(props) => props.height}px;
`;

const Margin = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
`;

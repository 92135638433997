import { LanguageRepository } from "../language.repository";
import {
  Application,
  Area,
  Guide,
  MasterData,
  MultilingualApplication,
  MultilingualArea,
  MultilingualGuide,
  MultilingualTour,
  Tour,
} from "../../entity";
import { IReadOnlyReactiveProperty } from "@agp/shared.common/use-reactive-property";

export class LocalizedEntityRepository {
  public readonly entities: IReadOnlyReactiveProperty<{
    application: Application;
    areas: Area[];
    tours: Tour[];
    guides: Guide[];
  }>;

  public constructor(
    languageRepository: LanguageRepository,
    masterData: MasterData
  ) {
    this.entities = languageRepository.currentLanguageId
      .getInstance()
      .select((languageId) => {
        const application = getLocalizedApplication(
          masterData.application,
          languageId
        );
        const areas = masterData.areas.map((x) =>
          getLocalizedArea(x, languageId)
        );
        const tours = masterData.tours
          .filter((x) => x.languageIds.includes(languageId))
          .map((x) => getLocalizedTour(x, languageId));
        const guides = masterData.guides
          .filter((x) => tours.map((x) => x.id).includes(x.tourId))
          .map((x) => getLocalizedGuide(x, languageId));
        return {
          application,
          areas,
          tours,
          guides,
        };
      });
  }
}

const getLocalizedApplication = (
  application: MultilingualApplication,
  languageId: string
): Application => {
  const { name, ...commonData } = application;
  return {
    name: name, // TODO: TLocalizedString からの変換
    languageId,
    ...commonData,
  };
};

const getLocalizedArea = (area: MultilingualArea, languageId: string): Area => {
  const { name, description, ...commonData } = area;
  return {
    name: name[languageId],
    languageId,
    description: description[languageId],
    ...commonData,
  };
};

const getLocalizedTour = (tour: MultilingualTour, languageId: string): Tour => {
  const { name, description, ...commonData } = tour;
  return {
    name: name[languageId],
    description: description[languageId],
    languageId,
    ...commonData,
  };
};

const getLocalizedGuide = (
  guide: MultilingualGuide,
  languageId: string
): Guide => {
  const { name, description, audioUrl, ...commonData } = guide;
  return {
    name: name[languageId],
    description: description[languageId],
    languageId,
    audioUrl: audioUrl[languageId],
    ...commonData,
  };
};

import PlayingAnimation from "../guides/playing-animation";
import { Divider } from "../../components/divider";
import { Guide } from "@agp/shared.user-app/entity";
import ServiceLocator from "../../../domain/service-locator";
import { twMerge } from "tailwind-merge";

const GuideItem = (props: {
  onClickGuide: (id: string) => void;
  guide: Guide;
  active: boolean;
  isListened: boolean;
}) => {
  const isPlaying = ServiceLocator.GuidePlayerModule.isPlaying.watch();
  return (
    <>
      <div
        className="cursor-pointer"
        onClick={() => props.onClickGuide(props.guide.id)}
      >
        <div className="flex items-center h-24">
          <div className="flex justify-center items-center h-full aspect-[4/3] bg-black text-white font-bold text-[3rem]">
            {props.guide.trackNumber}
          </div>
          {/*<img*/}
          {/*  className="h-full"*/}
          {/*  src={props.guide.thumbnailUrls[0] || "/no-image.png"}*/}
          {/*/>*/}
          <div className="flex-grow">
            <div className="flex items-center gap-2 pl-4">
              {props.active && <PlayingAnimation active={isPlaying} />}
              <div
                className={twMerge("text-xl", props.isListened && "font-bold")}
              >
                {props.guide.trackNumber} . {props.guide.name}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Divider />
    </>
  );
};

export default GuideItem;

import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";
import Icon, { IconType } from "../../icon";
import ServiceLocator from "../../../../domain/service-locator";

export type THeaderLeftComponent = "menu" | "back";
export type THeaderRightComponent = "map" | "guideList" | "tourList" | "keypad";

export interface HeaderProps {
  leftComponents: THeaderLeftComponent[];
  rightComponents: THeaderRightComponent[];
  onClickLeftComponent?: (index: number) => void;
  onClickRightComponent?: (index: number) => void;
}

const Header = (props: HeaderProps) => {
  const currentLabel =
    ServiceLocator.ApplicationStateRepository.currentLabel.watch();

  return (
    <>
      <MenuWrapper>
        <LeftMenu>
          {props.leftComponents.map((x, i) => (
            <Icon
              key={`header-left-icon-${i}`}
              type={getIconType(x)}
              onClick={() =>
                props.onClickLeftComponent && props.onClickLeftComponent(i)
              }
              style={{ height: 30, width: 30 }}
            />
          ))}
        </LeftMenu>
        <AnimatePresence>
          {currentLabel && (
            <Title
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              {currentLabel}
            </Title>
          )}
        </AnimatePresence>
        <RightMenu>
          {props.rightComponents.map((x, i) => (
            <Icon
              key={`header-right-icon-${i}`}
              type={getIconType(x)}
              onClick={() =>
                props.onClickRightComponent && props.onClickRightComponent(i)
              }
              style={{ height: 30, width: 30 }}
            />
          ))}
        </RightMenu>
      </MenuWrapper>
    </>
  );
};

export default Header;

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding-left: ${(props) => props.theme.marginHorizontal};
  padding-right: ${(props) => props.theme.marginHorizontal};
`;

const LeftMenu = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-shrink: 1;
  max-width: 85%;
`;

const RightMenu = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const Title = styled(motion.div)`
  flex-shrink: 1;
  color: ${(props) => props.theme.color.primaryText};
  font-size: 1.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
`;

const getIconType = (
  menuType: THeaderLeftComponent | THeaderRightComponent
): IconType => {
  switch (menuType) {
    case "menu":
      return "none";
    case "back":
      return "back";
    case "map":
      return "map";
    case "guideList":
    case "tourList":
      return "list";
    case "keypad":
      return "keypad";
  }
};

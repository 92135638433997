import {
  PagePaths,
  PageType,
} from "@agp/shared.user-app/application/context/navigation/type";
import { INavigationProps } from "@agp/shared.user-app/application/context/navigation";

export const createUrl = (
  pageType: PageType,
  applicationId: string,
  props: INavigationProps
) => {
  const path = PagePaths[pageType];

  const base = `?applicationId=${applicationId}`;
  const additional = Object.entries(props).map((x) => `${x[0]}=${x[1]}`);
  const query = [base, ...additional].join("&");
  return path + query;
};

import { Guide } from "../../entity";
import {
  IAudioPlayerTrack,
  IAudioSpeechTrack,
  ITrack,
} from "../../module/guide-player.module/audio-client";
import { Language } from "@agp/shared.common/entities/language";

export const guideToTrack = (
  guide: Guide,
  applicationName: string
): ITrack => ({
  id: guide.id,
  name: guide.name,
  trackNumber: guide.trackNumber,
  artist: applicationName,
  thumbnailUrls: guide.thumbnailUrls,
  text: guide.description,
});

export const guideToSpeechTrack = (
  guide: Guide,
  applicationName: string,
  languages: Language[]
): IAudioSpeechTrack => {
  const lang = languages.find((x) => x.id === guide.languageId)?.localeId;
  if (!lang)
    throw new Error(
      `languageId: ${guide.languageId} が MasterData 内の言語一覧にありませんでした`
    );
  return {
    ...guideToTrack(guide, applicationName),
    lang: languages.find((x) => x.id === guide.languageId)!.localeId,
  };
};

export const guideToPlayerTrack = (
  guide: Guide,
  applicationName: string
): IAudioPlayerTrack => ({
  ...guideToTrack(guide, applicationName),
  audioUrl: guide.audioUrl,
  duration: 0,
});

import { ApplicationTheme } from "@agp/shared.common/entities/application-config";
import { ITheme, IThemeColor } from "./type";

export const defaultTheme: ITheme = {
  color: {
    primary: "#840000",
    secondary: "#FC6F6F",
    primaryText: "#000000",
    secondaryText: "#A0A0A0",
    primaryBackground: "#F0F0F0",
    secondaryBackground: "#FFFFFF",
    divider: "#707070",
  },
  marginHorizontal: "7vw",
};

export const toStyledThemeColor = (theme: ApplicationTheme): IThemeColor => ({
  primary: theme.primaryColor,
  secondary: theme.secondaryColor,
  primaryText: theme.primaryTextColor,
  secondaryText: theme.secondaryTextColor,
  primaryBackground: theme.primaryBackgroundColor,
  secondaryBackground: theme.secondaryBackgroundColor,
  divider: theme.secondaryTextColor,
});

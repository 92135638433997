import { createContext, useContext } from "react";
import {
  NavigationOption,
  NavigationType,
} from "@agp/shared.user-app/application/context/navigation/type";

export interface INavigationCoreModule {
  push: (url: string, option?: NavigationOption) => void;
  back: (option?: NavigationOption) => void;
  change: (url: string, option?: NavigationOption) => void;
  onPreparedAnimation: () => void;
  navigationOption: NavigationOption;
  previousNavigateType: NavigationType | undefined;
}

const NavigationCoreModuleContext = createContext<INavigationCoreModule>(
  {} as INavigationCoreModule
);

export const NavigationCoreModuleProvider = (props: {
  children: any;
  module: INavigationCoreModule;
}) => {
  return (
    <NavigationCoreModuleContext.Provider value={props.module}>
      {props.children}
    </NavigationCoreModuleContext.Provider>
  );
};

export const useNavigationCoreModule = () =>
  useContext(NavigationCoreModuleContext);

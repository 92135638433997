import { PrefetchState, PrefetchType } from "./type";

export const checkSupportPreload = () => {
  try {
    return document.createElement("link").relList.supports("preload");
  } catch (e) {
    return false;
  }
};

export const loadAndCacheImages = async (urls: string[]): Promise<void> => {
  await Promise.all(
    urls
      .filter((x) => x !== "")
      .map(
        (x) =>
          new Promise<void>((resolve, _) => {
            const link = document.createElement("link");
            link.setAttribute("rel", "preload");
            link.setAttribute("as", "image");
            link.setAttribute("href", x);
            link.onload = () => {
              document.head.removeChild(link);
              resolve();
            };
            document.head.appendChild(link);
          })
      )
  );
};

export const createPrefetchState = () =>
  Object.values(PrefetchType).reduce((acc, cur) => {
    acc[cur] = false;
    return acc;
  }, {} as PrefetchState);

import { useCallback, useEffect } from "react";
import { useNavigationModule } from "@agp/shared.user-app/application/context/navigation";
import { motion } from "framer-motion";
import { delay } from "../../tours/acoustiguide/expandable-tour-card";

export const MinpakuSplashPage = () => {
  const { push } = useNavigationModule();

  const handleClick = useCallback(() => {
    push("top", {
      pageInType: "fade",
      pageOutType: "fade",
    });
  }, []);

  useEffect(() => {
    const timer = async () => {
      await delay(3000);
      handleClick();
    };
    timer();
  }, []);

  return (
    <div
      className="bg-black h-[calc(var(--vh,1vh)*100)] flex flex-col justify-center items-center"
      onClick={handleClick}
    >
      <motion.img
        src="/minpaku-demo/logos/acoustiguide.png"
        // initial={{ scale: 0.8 }}
        // animate={{ scale: 1 }}
        // transition={{ duration: 1.5 }}
        className="w-[75%]"
      />
    </div>
  );
};

import { ReactNode, useCallback, useState } from "react";
import Header, {
  THeaderLeftComponent,
  THeaderRightComponent,
} from "./header/header";
import styled from "styled-components";
import TopBar from "./top-bar";
import { NavigationOption } from "@agp/shared.user-app/application/context/navigation/type";
import { useNavigationModule } from "@agp/shared.user-app/application/context/navigation";
import Modal from "../modal";
import { Keypad } from "./keypad";
import ServiceLocator from "../../../domain/service-locator";

export const headerHeight = 76;

export interface LayoutProps {
  headerLeftComponents?: THeaderLeftComponent[];
  headerRightComponents?: THeaderRightComponent[];
  backTransitionOption?: NavigationOption;
  children: ReactNode;
}

const Layout = (props: LayoutProps) => {
  const applicationStateRepository = ServiceLocator.ApplicationStateRepository;
  const currentAreaId = applicationStateRepository.currentAreaId.watch();
  const currentTourId = applicationStateRepository.currentTourId.watch();
  const currentTrack = ServiceLocator.GuidePlayerModule.currentTrack.watch();
  const [isTopBarOpen, setIsTopBarOpen] = useState(false);
  const { change, back } = useNavigationModule();
  const [isKeypadOpen, setIsKeypadOpen] = useState(false);

  const onClickHeaderLeftComponent = useCallback(
    (index: number) => {
      const component = props.headerLeftComponents![index];
      if (component === "menu") setIsTopBarOpen(true);
      else if (component === "back") {
        back(props.backTransitionOption);
      }
    },
    [props.headerLeftComponents]
  );

  const onClickHeaderRightComponent = useCallback(
    (index: number) => {
      const component = props.headerRightComponents![index];
      switch (component) {
        case "keypad":
          setIsKeypadOpen(true);
          break;
        case "map":
        case "guideList":
        case "tourList": {
          const nextPage =
            component === "map"
              ? "map"
              : component === "guideList"
              ? "guides"
              : "tours";
          const navigationProps =
            nextPage === "guides" || nextPage === "map"
              ? {
                  areaId: currentAreaId,
                  tourId: currentTourId,
                }
              : { areaId: currentAreaId };
          const option: NavigationOption = {
            pageInType: "fade",
            pageOutType: "fade",
          };
          change(nextPage, navigationProps, option);
          break;
        }
      }
    },
    [props.headerLeftComponents, change, currentAreaId, currentTourId]
  );

  return (
    <>
      <Wrapper>
        <HeaderWrapper>
          <Header
            leftComponents={props.headerLeftComponents || []}
            rightComponents={props.headerRightComponents || []}
            onClickLeftComponent={onClickHeaderLeftComponent}
            onClickRightComponent={onClickHeaderRightComponent}
          />
        </HeaderWrapper>
        <BodyWrapper isPaddingBottom={currentTrack != null}>
          {props.children}
        </BodyWrapper>
      </Wrapper>
      <TopBar visible={isTopBarOpen} onHide={() => setIsTopBarOpen(false)} />
      <Modal isModalOpen={isKeypadOpen} onClose={() => setIsKeypadOpen(false)}>
        <Keypad onComplete={() => setIsKeypadOpen(false)} />
      </Modal>
    </>
  );
};

export default Layout;

const Wrapper = styled.div`
  min-height: ${window?.innerHeight + "px" ?? "100vh"};
`;

const HeaderWrapper = styled.div`
  height: ${headerHeight}px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  backdrop-filter: blur(5px);
`;

const BodyWrapper = styled.div<{ isPaddingBottom: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding-top: ${headerHeight}px;
  overflow-x: hidden;
  overflow-y: visible;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

const ListIcon = (props: { color: string; circleColor: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79 79">
    <g
      id="グループ_604"
      data-name="グループ 604"
      transform="translate(-4743.904 -2417.832)"
    >
      <circle
        id="楕円形_172"
        data-name="楕円形 172"
        cx="39.5"
        cy="39.5"
        r="39.5"
        transform="translate(4743.904 2417.832)"
        fill={props.circleColor}
      />
      <g
        id="グループ_601"
        data-name="グループ 601"
        transform="translate(3811.643 2328.902)"
      >
        <line
          id="線_1"
          data-name="線 1"
          x1="24.73"
          transform="translate(966.124 113.5)"
          fill="none"
          stroke={props.color}
          strokeLinecap="round"
          strokeWidth="8"
        />
        <line
          id="線_9"
          data-name="線 9"
          transform="translate(952.438 113.5)"
          fill="none"
          stroke={props.color}
          strokeLinecap="round"
          strokeWidth="8"
        />
        <line
          id="線_2"
          data-name="線 2"
          x1="24.957"
          transform="translate(966.125 128.429)"
          fill="none"
          stroke={props.color}
          strokeLinecap="round"
          strokeWidth="8"
        />
        <line
          id="線_8"
          data-name="線 8"
          x1="0.117"
          transform="translate(952.44 128.429)"
          fill="none"
          stroke={props.color}
          strokeLinecap="round"
          strokeWidth="8"
        />
        <line
          id="線_6"
          data-name="線 6"
          x1="24.957"
          transform="translate(966.125 143.358)"
          fill="none"
          stroke={props.color}
          strokeLinecap="round"
          strokeWidth="8"
        />
        <line
          id="線_7"
          data-name="線 7"
          x1="0.117"
          transform="translate(952.44 143.358)"
          fill="none"
          stroke={props.color}
          strokeLinecap="round"
          strokeWidth="8"
        />
      </g>
    </g>
  </svg>
);

export default ListIcon;

const ArrowRightIcon = (props: { color: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.058 45.605">
    <g
      id="グループ_3"
      data-name="グループ 3"
      transform="translate(5.652 39.953) rotate(-90)"
    >
      <line
        id="線_1"
        data-name="線 1"
        x2="17.151"
        y2="15.755"
        transform="translate(0 0)"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeWidth="8"
      />
      <line
        id="線_2"
        data-name="線 2"
        x1="17.151"
        y2="15.646"
        transform="translate(17.151 0)"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeWidth="8"
      />
    </g>
  </svg>
);

export default ArrowRightIcon;

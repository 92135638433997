import styled from "styled-components";
import { motion } from "framer-motion";

export const Button = (props: any) => (
  <StyledButton whileTap={{ scale: 0.97 }} {...props} />
);

export const StyledButton = styled(motion.button)`
  background: ${(props) => props.theme.color.primary};
  color: white;
  border-radius: 10px;
  height: 30px;
  width: 300px;
  border: 0;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
`;

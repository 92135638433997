import AnimatedPageTransition from "../../../domain/module/navigation/animated-page-transition";
import ServiceLocator from "../../../domain/service-locator";
import { MinpakuSplashPage } from "./minpaku";

const SplashPage = () => {
  const uiTemplateModule = ServiceLocator.UiTemplateModule;

  return (
    <AnimatedPageTransition>
      {uiTemplateModule.uiTemplate === "Minpaku" ? (
        <MinpakuSplashPage />
      ) : (
        <></>
      )}
    </AnimatedPageTransition>
  );
};

export default SplashPage;

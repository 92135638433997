class StyleManager {
  private didStyleChanged: (() => void)[] = [];

  public configureStyle = () => {
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
      this.didStyleChanged.forEach((x) => x());
    };
    setVh();
    window.addEventListener("resize", setVh);
    window.addEventListener("DOMContentLoaded", setVh);
  };

  public registerDidStyleChanged = (callback: () => void) => {
    this.didStyleChanged.push(callback);
  };
}

export default new StyleManager();

import React from "react";
import Document from "./_document";
import AppContainer from "./app-container";
import { HeaderProvider } from "./presentation/components/layout/context";
import { ThemeProvider } from "styled-components";
import ServiceLocator from "./domain/service-locator";

const App = () => {
  const theme = ServiceLocator.ThemeStore.theme;

  return (
    <ThemeProvider theme={theme}>
      <Document>
        <HeaderProvider>
          <AppContainer />
        </HeaderProvider>
      </Document>
    </ThemeProvider>
  );
};

export default App;

import AnimatedPageTransition from "../../../domain/module/navigation/animated-page-transition";
import ServiceLocator from "../../../domain/service-locator";
import { useMemo } from "react";
import { TTemplate } from "../../../domain/entities/template";
import { TourLocalMap } from "./tour-local-map";
import { AreaLocalMap } from "./area-local-map";

const MapPage = () => {
  const localizedEntityRepository = ServiceLocator.LocalizedEntityRepository;
  const application = localizedEntityRepository.entities.watch().application;

  const template = useMemo<TTemplate>(
    () =>
      application?.id === "08dcc024-e8ac-4fc3-81bd-b80fd82d7f83"
        ? "Acoustiguide"
        : "General",
    [application]
  );
  return (
    <AnimatedPageTransition>
      {template === "Acoustiguide" ? <AreaLocalMap /> : <TourLocalMap />}
    </AnimatedPageTransition>
  );
};

export default MapPage;

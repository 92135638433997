import { IColorSchemaConfig } from "@agp/shared.user-app/infrastructure/color-schema-config";

export const colorSchemaConfig: IColorSchemaConfig = {
  colorSchema:
    typeof window === "undefined"
      ? null
      : window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light",
};

import {
  IReadOnlyReactiveProperty,
  ReactivePropertyInstance,
} from "@agp/shared.common/use-reactive-property";
import { MasterData } from "../../entity";
import { IPersistentDataModule } from "../persistent-data.module";

export class LanguageRepository {
  private readonly masterData: MasterData;
  private readonly persistentDataModule: IPersistentDataModule;

  public get languages() {
    return this.masterData.languages;
  }

  private _currentLanguageId: ReactivePropertyInstance<string>;
  public currentLanguageId: IReadOnlyReactiveProperty<string>;

  public constructor(
    masterData: MasterData,
    persistentDataModule: IPersistentDataModule
  ) {
    this.masterData = masterData;
    this.persistentDataModule = persistentDataModule;
    this._currentLanguageId = new ReactivePropertyInstance<string>(
      this.languages[0].id
    );
    this.currentLanguageId =
      this._currentLanguageId.toReadOnlyReactiveProperty();
  }

  public updateCurrentLanguageId = (id: string) => {
    this._currentLanguageId.setValue(id);
    this.persistentDataModule.updateCurrentLanguageId(id);
  };
}

import { ApiClientFilter } from "./api-client-filter";
import { ServiceContext } from "./service-context";
import { ApiError } from "./api-error";

export type THttpMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

export class ApiClient {
  private readonly endpoint: string;
  private invokers: ((context: ServiceContext) => Promise<void>)[] = [];

  constructor(endpoint: string) {
    this.endpoint = endpoint;
    this.invokers.push(this.sendImpl);
  }

  public AddFilter(filter: ApiClientFilter) {
    const previousInvoker = this.invokers[this.invokers.length - 1];
    this.invokers.push((c: ServiceContext) =>
      filter.invoke(c, previousInvoker),
    );
  }

  public async send(path: string, method: THttpMethod): Promise<void>;

  public async send<TRequest>(
    path: string,
    method: THttpMethod,
    request: TRequest,
  ): Promise<void>;

  public async send<TResponse>(
    path: string,
    method: THttpMethod,
  ): Promise<TResponse>;

  public async send<TRequest, TResponse>(
    path: string,
    method: THttpMethod,
    request: TRequest,
  ): Promise<TResponse>;

  public async send<TRequest, TResponse>(
    path: string,
    method: THttpMethod,
    request?: TRequest,
  ): Promise<void | TResponse> {
    const context = new ServiceContext(
      new Request(this.endpoint + path, {
        method,
        headers: new Headers(),
        body: request ? JSON.stringify(request) : undefined,
      }),
    );
    await this.invokers[this.invokers.length - 1](context);
    if (!context.response!.ok) throw new ApiError(context.response!);

    const json = await context.response!.text();
    if (json && json.length > 0) return JSON.parse(json) as TResponse;
    return;

    // 一応こっちのパターンも保持
    // const invoker = this.filters.reduce(
    //   (acc, cur) => (c: ServiceContext) => cur.invoke(c, acc),
    //   this.sendImpl as (context: ServiceContext) => Promise<void>,
    // );
    // await invoker(context);
  }

  private async sendImpl(context: ServiceContext): Promise<void> {
    context.response = await fetch(context.request);
  }
}

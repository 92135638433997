import AnimatedList from "../../../components/animated-list";
import styled from "styled-components";
import { Image } from "semantic-ui-react";
import Icon from "../../../components/icon";
import { useToursModel } from "./model";
import ServiceLocator from "../../../../domain/service-locator";

const ToursList = () => {
  const model = useToursModel();
  const { theme } = ServiceLocator.ThemeStore;
  return (
    <Wrapper>
      <AnimatedList
        disableAnimation={!model.enableTourListAnimation}
        items={model.tours.map((x, i) => (
          <ListItem
            key={`tours-tour-${i}`}
            onClick={() => model.onClickTour(x.id)}
          >
            <ListItemInfo>
              <Thumbnail
                src={x.thumbnailUrl === "" ? "/no-image.png" : x.thumbnailUrl}
              />
              <Title>{x.name}</Title>
            </ListItemInfo>
            <Icon
              type={"arrow right"}
              style={{ width: 10 }}
              color={theme.color.secondaryText}
            />
          </ListItem>
        ))}
      />
    </Wrapper>
  );
};

export default ToursList;

const Wrapper = styled.div`
  margin: 0 ${(props) => props.theme.marginHorizontal};
  height: 100%;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  padding: 8px 15px 8px 8px;
  background: ${(props) => props.theme.color.secondaryBackground};
  height: 80px;
`;

const ListItemInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 100%;
`;

const Thumbnail = styled(Image)`
  border-radius: 12px;
  height: 100%;
`;

const Title = styled.div`
  font-size: 1.6rem;
`;

import { Dimmer, Loader } from "semantic-ui-react";
import styled from "styled-components";

const Loading = () => {
  return (
    <LoaderWrapper>
      <Dimmer active inverted>
        <Loader inverted>Loading...</Loader>
      </Dimmer>
    </LoaderWrapper>
  );
};

const LoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

export default Loading;

import { ILocalStorageClient } from "../../infrastructure/local-storage-client";
import { getKey } from "./helper";
import { MasterData } from "../../entity";

export interface IPersistentDataModule {
  getListenedGuideIds: () => string[];
  getCurrentLanguageId: () => string | null;
  updateListenedGuideIds: (ids: string[]) => void;
  updateCurrentLanguageId: (id: string) => void;
}

export class PersistentDataModule implements IPersistentDataModule {
  private readonly localStorageClient: ILocalStorageClient;
  private readonly applicationId: string;

  public constructor(
    localStorageClient: ILocalStorageClient,
    masterData: MasterData
  ) {
    this.localStorageClient = localStorageClient;
    this.applicationId = masterData.application.id;
  }

  public getListenedGuideIds = () =>
    this.localStorageClient.get(
      getKey(this.applicationId, "listenedGuideIds")
    ) || [];

  public getCurrentLanguageId = () =>
    this.localStorageClient.get(
      getKey(this.applicationId, "currentLanguageId")
    );

  updateListenedGuideIds = (ids: string[]) =>
    this.localStorageClient.set(
      getKey(this.applicationId, "listenedGuideIds"),
      ids
    );
  updateCurrentLanguageId = (id: string) =>
    this.localStorageClient.set(
      getKey(this.applicationId, "currentLanguageId"),
      id
    );
}

import Dropdown from "../../../components/dropdown";
import styled from "styled-components";
import { Button } from "../../../components/button";
import { useTopModel } from "../model";

export const TopGeneral = () => {
  const model = useTopModel();
  return (
    <Wrapper>
      <>
        <BackgroundWrapper>
          <Background />
          <HeaderImageWrapper>
            <HeaderImage src={"/samples/top.png"} />
          </HeaderImageWrapper>
        </BackgroundWrapper>
        <TitleWrapper>
          {/*//TODO: LocalizedEntity から表示文言を取得できるようにする*/}
          <ApplicationBaseTitle>
            {model.currentLanguageId === "597127b8-726e-4186-97dd-a6f1d94bf368"
              ? "音声ガイドアプリケーション"
              : "Audio Guide Application"}
          </ApplicationBaseTitle>
          <ApplicationTitleWrapper>
            <ApplicationTitle1>
              {model.currentLanguageId ===
              "597127b8-726e-4186-97dd-a6f1d94bf368"
                ? "中板橋駅"
                : "Naka-Itabashi Station"}
            </ApplicationTitle1>
            <ApplicationTitle2
              fontSize={
                model.currentLanguageId ===
                "597127b8-726e-4186-97dd-a6f1d94bf368"
                  ? 4
                  : 3
              }
            >
              {model.currentLanguageId ===
              "597127b8-726e-4186-97dd-a6f1d94bf368"
                ? "なかいた商店街"
                : "Nakaita Shopping Street"}
            </ApplicationTitle2>
          </ApplicationTitleWrapper>
        </TitleWrapper>
        <ContentWrapper>
          <Content>
            <Dropdown
              label="Language"
              options={model.languageDropdownOptions}
              selectedId={model.currentLanguageId}
              onChange={model.updateCurrentLanguageId}
            />
            <StartButton onClick={model.onClickStartButton}>
              {model.currentLanguageId ===
              "597127b8-726e-4186-97dd-a6f1d94bf368"
                ? "ガイドを始める"
                : "Start Guide"}
            </StartButton>
          </Content>
        </ContentWrapper>
      </>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: ${window?.innerHeight + "px" ?? "100vh"};
`;

const BackgroundWrapper = styled.div`
  position: absolute;
  height: ${window?.innerHeight + "px" ?? "100vh"};
  width: 100vw;
`;

const Background = styled.div`
  position: absolute;
  background: black;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

const HeaderImageWrapper = styled.div`
  position: absolute;
  bottom: 30%;
  left: 0;
  right: 0;

  &:before {
    content: "";
    position: absolute;
    top: 71.5%; // 画面中央からから画面70%までグラデーションさせる
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    background: linear-gradient(rgba(255, 255, 255, 0), black);
  }
`;

const HeaderImage = styled.img`
  width: 100%;
  display: inline-block;
  line-height: 0;
`;

const TitleWrapper = styled.div`
  position: absolute;
  height: ${(window?.innerHeight * 20) / 100 + "px" ?? "20vh"};
  width: 100%;
  top: ${(window?.innerHeight * 50) / 100 + "px" ?? "50vh"};
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ApplicationBaseTitle = styled.div`
  font-size: 1.6rem;
  line-height: 2rem;
`;

const ApplicationTitleWrapper = styled.div`
  margin-bottom: 15px;
`;

const ApplicationTitle1 = styled.div`
  font-size: 2.5rem;
  line-height: 3rem;
`;

const ApplicationTitle2 = styled.div<{ fontSize?: number }>`
  font-size: ${(props) => props.fontSize ?? 4}rem;
  line-height: 5rem;
`;

const ContentWrapper = styled.div`
  position: absolute;
  height: ${(window?.innerHeight * 30) / 100 + "px" ?? "30vh"};
  width: 100%;
  bottom: 0;
`;

const Content = styled.div`
  width: 260px;
  margin: auto;
  padding-top: 10px;
`;

const StartButton = styled(Button)`
  margin-top: 25px;
  color: white;
  width: 100%;
  height: 50px;
  font-size: 2.5rem;
`;

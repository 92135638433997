export const BackIcon = ({
  color,
  width,
}: {
  color: string;
  width?: number;
}) => (
  <svg
    width={width || "64"}
    height={width ? (width * 44) / 64 : "44"}
    viewBox="0 0 16 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5 0L0 6L5 11H16V0H5Z" fill={color} />
    <path d="M7 2L14 9M14 2L7 9" stroke="white" strokeWidth="0.8" />
  </svg>
);
